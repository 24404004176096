<template>
  <div
    :class="[
      'vd-padding-right-20 vd-padding-top-20 vd-padding-bottom-20 vd-border-light-grey vms-filming-details__wrapper',
      isMobileScreen ? 'vms-filming-details__wrapper--mobile' : '',
    ]"
  >
    <project-filming-details-header-mobile
      v-if="isMobileScreen"
      :filming-detail="filmingDetail"
      :is-on-hold="isOnHold"
      :is-hold-shoot-job-loading="isHoldShootJobLoading"
      @click:hold="holdFilmingJobHandler"
      @click:call-sheet="openCallSheetForm"
      @click:view="viewFormDetails"
    ></project-filming-details-header-mobile>

    <template v-else>
      <project-filming-details-header-desktop
        :filming-detail="filmingDetail"
        :is-on-hold="isOnHold"
        :is-hold-shoot-job-loading="isHoldShootJobLoading"
        @click:hold="holdFilmingJobHandler"
        @click:call-sheet="openCallSheetForm"
        @click:edit="showEditFilming"
      ></project-filming-details-header-desktop>

      <project-filming-details-list-desktop
        :filming-detail="filmingDetail"
        :is-on-hold="isOnHold"
        @click:edit="showEditFilming"
        @add:pre-production="addPreProduction"
        @remove:pre-production="removePreProduction"
      ></project-filming-details-list-desktop>
    </template>

    <edit-filming-details-form
      v-if="canDisplayEditForm"
      :is-view-mode="isViewMode && isMobileScreen"
      :class="[!isMobileScreen ? 'vd-margin-top-large' : '']"
      @edit="isViewMode = false"
    ></edit-filming-details-form>

    <filming-call-sheet-form
      v-if="showCallSheetForm"
      :class="[!isMobileScreen ? 'vd-margin-top-medium' : '']"
    ></filming-call-sheet-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import EditFilmingDetailsForm from '@views/project/components/forms/filming-details/filming-details-form'
import FiltersMixin from '@mixins/filters-mixin'
import ApiCustomIncludes from '@configs/api-custom-includes'
import ProjectFilmingDetailsHeaderDesktop from './components/project-filming-details-header-desktop'
import ProjectFilmingDetailsHeaderMobile from './components/project-filming-details-header-mobile'
import ProjectFilmingDetailsListDesktop from './components/project-filming-details-list-desktop'
import FilmingCallSheetForm from '@views/project/components/forms/filming-details/filming-call-sheet-form'

import VmsConstants from '@configs/vms-constants'
import SweetAlert from '@plugins/sweet-alert'

export default {
  components: {
    EditFilmingDetailsForm,
    ProjectFilmingDetailsHeaderDesktop,
    ProjectFilmingDetailsHeaderMobile,
    ProjectFilmingDetailsListDesktop,
    FilmingCallSheetForm,
  },

  mixins: [FiltersMixin],

  props: {
    filmingDetail: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {
      isViewMode: false,
    }
  },

  computed: {
    ...mapGetters({
      selectedFilmingDetails: 'project/shoot/selectedFilmingDetails',
      filmingDetailsEditFormOpen: 'project/shoot/filmingDetailsEditFormOpen',
      preProductions: 'project/pre-production/preProductions',
      isMobileScreen: 'common/isMobileScreen',
      projectDetails: 'project/projectDetails',
      callSheetFormOpen: 'project/shoot/callSheetFormOpen',
      filmingDetailsNewFormOpen: 'project/shoot/filmingDetailsNewFormOpen',
      holdShootJobLoading: 'project/shoot/holdShootJobLoading',
      isProjectOnHold: 'project/isProjectOnHold',
    }),

    canDisplayEditForm() {
      if (
        this.filmingDetailsEditFormOpen &&
        this.selectedFilmingDetails.order_job_id ===
          this.filmingDetail.order_job_id
      ) {
        return true
      }

      return false
    },

    showCallSheetForm() {
      return (
        this.selectedFilmingDetails?.order_job_id ===
          this.filmingDetail.order_job_id && this.callSheetFormOpen
      )
    },

    isOnHold() {
      return (
        this.filmingDetail.order_job_status.id ===
        VmsConstants.orderJobStatuses.ORDER_JOB_ON_HOLD
      )
    },

    isHoldShootJobLoading() {
      return (
        this.selectedFilmingDetails &&
        this.selectedFilmingDetails.order_job_id ===
          this.filmingDetail.order_job_id &&
        this.holdShootJobLoading
      )
    },
  },

  provide: {
    formActionType: 'edit',
  },

  methods: {
    ...mapActions({
      showAddNewFilmingDetailsForm:
        'project/shoot/showAddNewFilmingDetailsForm',
      showEditFilmingDetailsForm: 'project/shoot/showEditFilmingDetailsForm',
      updateSelectedFilmingDetails:
        'project/shoot/updateSelectedFilmingDetails',
      linkPreProduction: 'project/shoot/linkPreProduction',
      unlinkPreProduction: 'project/shoot/unlinkPreProduction',
      updateCallSheetFormOpen: 'project/shoot/updateCallSheetFormOpen',
      holdShootJob: 'project/shoot/holdShootJob',
    }),

    showProjectOnHoldWarning() {
      SweetAlert.fire({
        title: 'Project On Hold',
        html: `<br> Please take the project off hold before making changes on jobs.`,
        showCancelButton: false,
        confirmButtonText: 'Close',
      })
    },

    showEditFilming() {
      if (this.isProjectOnHold) {
        this.showProjectOnHoldWarning()
      } else {
        if (
          this.selectedFilmingDetails?.order_job_id ===
            this.filmingDetail.order_job_id &&
          this.filmingDetailsEditFormOpen
        ) {
          return
        }

        this.closeFilmingJobForms().then((confirmed) => {
          if (confirmed) {
            this.updateCallSheetFormOpen(false)
            this.updateSelectedFilmingDetails(this.filmingDetail)
            this.showEditFilmingDetailsForm(true)
            this.showAddNewFilmingDetailsForm(false)
          }
        })
      }
    },

    viewFormDetails() {
      this.isViewMode = true
      this.showEditFilming()
    },

    addPreProduction(selectedPreProductions) {
      this.closeFilmingJobForms().then((confirmed) => {
        if (confirmed) {
          this.updateCallSheetFormOpen(false)
          this.updateSelectedFilmingDetails(null)
          this.showEditFilmingDetailsForm(false)
          this.showAddNewFilmingDetailsForm(false)

          this.linkPreProduction({
            project_id: this.$route.params.projectId,
            shoot_job_id: this.filmingDetail.id,
            name: this.filmingDetail.name,
            pre_production_ids: selectedPreProductions,
            includes: ApiCustomIncludes.filmingDetailsList,
          })
        }
      })
    },

    removePreProduction(selectedPreProduction) {
      let payload = {
        pre_production_ids: [selectedPreProduction],
      }
      this.unlinkPreProduction({
        project_id: this.$route.params.projectId,
        name: this.filmingDetail.name,
        order_job_id: this.filmingDetail.id,
        includes: ApiCustomIncludes.filmingDetailsList,
        payload: payload,
      })
    },

    openCallSheetForm() {
      if (
        this.selectedFilmingDetails?.order_job_id ===
          this.filmingDetail.order_job_id &&
        this.callSheetFormOpen
      ) {
        return
      }

      this.closeFilmingJobForms().then((confirmed) => {
        if (confirmed) {
          this.updateSelectedFilmingDetails(this.filmingDetail)
          this.showEditFilmingDetailsForm(false)
          this.showAddNewFilmingDetailsForm(false)
          this.updateCallSheetFormOpen(true)
        }
      })
    },

    holdFilmingJobHandler() {
      if (this.isProjectOnHold) {
        this.showProjectOnHoldWarning()
      } else {
        if (
          this.isOnHold &&
          this.filmingDetailsEditFormOpen &&
          this.selectedFilmingDetails.order_job_id ===
            this.filmingDetail.order_job_id
        ) {
          return
        }

        if (
          this.filmingDetailsNewFormOpen ||
          this.filmingDetailsEditFormOpen ||
          this.callSheetFormOpen
        ) {
          SweetAlert.fire({
            title: 'Warning!',
            html: 'Are you sure you want to cancel your changes?',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            heightAuto: false,
            scrollbarPadding: false,
          }).then((result) => {
            if (result.isConfirmed) {
              this.holdFilmingJob()
            }
          })
        } else {
          this.holdFilmingJob()
        }
      }
    },

    holdFilmingJob() {
      this.showAddNewFilmingDetailsForm(false)
      this.updateCallSheetFormOpen(false)

      if (this.isOnHold) {
        this.updateSelectedFilmingDetails(this.filmingDetail)
        this.showEditFilmingDetailsForm(true)

        return
      }

      this.updateSelectedFilmingDetails(null)
      this.showEditFilmingDetailsForm(false)

      this.holdShootJob({
        project_id: this.projectDetails.id,
        order_job: this.filmingDetail,
        includes: ApiCustomIncludes.filmingDetailsList,
      })
    },

    closeFilmingJobForms() {
      if (
        this.filmingDetailsNewFormOpen ||
        this.filmingDetailsEditFormOpen ||
        this.callSheetFormOpen
      ) {
        return new Promise((res) =>
          SweetAlert.fire({
            title: 'Warning!',
            html: 'Are you sure you want to cancel your changes?',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            heightAuto: false,
            scrollbarPadding: false,
          }).then((result) => {
            res(result.isConfirmed)
          })
        )
      } else {
        return Promise.resolve(true)
      }
    },
  },
}
</script>
