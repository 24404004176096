<template>
  <div class="vms-call-sheet-list vd-background-lightest-grey">
    <call-sheet-contact-item
      :value="value"
      default-role="Site Contact"
      @click:update="handleUpdate"
      @click:remove="handleRemove"
    ></call-sheet-contact-item>
  </div>
</template>
<script>
import CallSheetContactItem from './filming-call-sheet-contact-item'

export default {
  components: {
    CallSheetContactItem,
  },

  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  methods: {
    handleUpdate(contact) {
      this.$emit('input', contact)
    },

    handleRemove() {
      this.$emit('input', {
        name: '',
        phone: '',
        email: '',
        photo_url: '',
        role: 'Site Contact',
      })
    },
  },
}
</script>
