<template>
  <v-app>
    <div
      v-if="isBulkAction"
      class="vd-display-flex vd-align-center vd-padding-inline-small"
    >
      <input
        id="reassignUserCheckbox"
        type="checkbox"
        class="vd-margin-right-small"
        name="reassignUserCheckbox"
        @change="handleReassignUser"
      />

      <label for="reassignUserCheckbox" class="vd-margin-0 vd-display-flex">
        <span class="vd-margin-right-small">Tick to re-assign</span>
      </label>
    </div>
    <div v-if="canShowAssignUserFields" class="row no-gutters vd-align-center">
      <div class="col-md-6">
        <div
          :class="[
            'vms-event-modal-content__field vd-padding-small',
            !assignToUser && assignToFreelancer
              ? 'vms-event-modal-content__field--inactive'
              : '',
          ]"
        >
          <v-autocomplete
            ref="autocomplete"
            :value="assignToUser"
            :disabled="disabled"
            :class="[
              'vd-field vms-event-modal-content__field',
              isAssignedUserInactive
                ? 'vd-input-error'
                : assignToUser
                ? 'vd-input-valid'
                : '',
            ]"
            :items="activeUsersList"
            :hide-selected="true"
            :menu-props="menuProps"
            placeholder="Select a team member (Leave this blank for unassigned)"
            persistent-placeholder
            item-text="full_name"
            item-value="id"
            label="Assign to"
            cache-items
            clearable
            @change="handleAssignToUserChange($event)"
          >
            <template v-slot:label>
              <label>Assign to</label>
              <text-popover
                v-if="isAssignedUserInactive"
                class="vd-display-inline-block vd-align-middle"
                tooltip-icon="vd-icon icon-circle-question"
                custom-tooltip-class="vd-h5 vd-padding-left-extra-small vd-cursor-pointer"
                tooltip-id="assignToTooltip"
                tooltip-text="Inactive user selected"
              ></text-popover>
            </template>
            <template v-slot:selection="{ item }">
              <div
                v-if="item"
                class="vd-display-flex vd-align-center vd-margin-bottom-extra-small"
              >
                <user-profile
                  :user="item"
                  :custom-avatar-font-size="20"
                  class="vd-margin-right-10"
                ></user-profile>
                <span>{{ item.full_name }}</span>
              </div>
            </template>
            <template v-slot:item="{ item }">
              <div
                v-if="item"
                class="vd-display-flex vd-align-center vd-margin-bottom-extra-small"
              >
                <user-profile
                  :user="item"
                  :custom-avatar-font-size="30"
                  class="vd-margin-right-small"
                ></user-profile>
                <span>{{ item.full_name }}</span>
              </div>
            </template>
            <template v-slot:no-data>
              <!-- display no result message if not loading else display empty div -->
              <div>
                <div
                  v-if="!hasEventUsers"
                  class="vd-padding-block-small vd-padding-inline-large"
                >
                  No results found
                </div>
              </div>
            </template>
          </v-autocomplete>
        </div>
      </div>
      <div class="col-md-1">
        <div class="vd-text-align-center">or</div>
      </div>
      <div class="col-md-5">
        <div
          :class="[
            'vms-event-modal-content__field vd-padding-small',
            !assignToFreelancer && assignToUser
              ? 'vms-event-modal-content__field--inactive'
              : '',
          ]"
        >
          <input-component
            :disabled="disabled"
            :value="assignToFreelancer"
            :container-custom-classes="[
              'vd-field',
              assignToFreelancer ? 'vd-input-valid' : '',
            ]"
            type="text"
            name="freelancer"
            label="Enter a name for freelancer"
            placeholder="Type a freelancer's name"
            autocomplete="off"
            @formInput="handleAssignToFreelancer($event)"
          ></input-component>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import UserProfile from '@components/user-profile/user-profile'
import InputComponent from '@components/form/form-input'
import Vuetify from 'vuetify'
import { find, size, filter } from 'lodash'
import TextPopover from '@components/tooltips/text-popover'

export default {
  vuetify: new Vuetify(),

  components: {
    UserProfile,
    InputComponent,
    TextPopover,
  },

  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    assignToFreelancer: {
      type: String,
      required: false,
      default: '',
    },

    assignToUser: {
      type: Object,
      required: false,
      default: null,
    },

    isBulkAction: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      menuProps: {
        closeOnClick: true,
        offsetY: true,
      },
      canShowAssignUserFields: false,
      isAssignedUserInactive: false,
    }
  },

  computed: {
    ...mapGetters({
      internalUsersList: 'user/internalUsersList',
      selectedEvent: 'calendar/selectedEvent',
    }),

    hasEventUsers() {
      return size(this.internalUsersList) > 0
    },

    activeUsersList() {
      return filter(this.internalUsersList, (user) => {
        return user.status !== 'Inactive'
      })
    },
  },

  mounted() {
    if (!this.isBulkAction) {
      this.canShowAssignUserFields = true
    }

    if (
      this.selectedEvent &&
      this.selectedEvent.user &&
      this.selectedEvent.user.status === 'Inactive'
    ) {
      this.isAssignedUserInactive = true
      this.handleAssignToUserChange(null)
    }
  },

  methods: {
    ...mapActions({
      setCanBulkReassignUser: 'calendar/setCanBulkReassignUser',
    }),

    handleReassignUser() {
      this.canShowAssignUserFields = !this.canShowAssignUserFields
      this.setCanBulkReassignUser(this.canShowAssignUserFields)
    },

    handleAssignToUserChange(user) {
      if (!user) {
        this.$emit('update:assignToUser', null)
        return
      }

      const selectedUser = find(this.internalUsersList, { id: user })

      this.isAssignedUserInactive = false

      this.$emit('update:assignToUser', selectedUser)
      this.$emit('update:assignToFreelancer', null)
    },

    handleAssignToFreelancer(freelancer) {
      if (freelancer) {
        this.$emit('update:assignToUser', null)
      }
      this.$emit('update:assignToFreelancer', freelancer)
    },
  },
}
</script>

<style lang="scss">
@import '@styles/plugins/vuetify';
</style>
