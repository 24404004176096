<template>
  <div>
    <div class="vd-h4 vd-margin-bottom-medium">Comments/Remarks</div>
    <div class="row">
      <div class="col-sm-4">
        <div class="vd-dark-grey vd-margin-bottom-small">
          Parking is arranged?
        </div>
        <label
          for="parkingIsRequiredInput"
          class="vms-call-sheet-form__checkbox vd-margin-0"
        >
          <input
            id="parkingIsRequiredInput"
            type="checkbox"
            :checked="value.is_parking_required"
            @change="handleIsParkingRequired"
          />
          <span class="vd-display-inline-block vd-padding-left-small">
            Yes
          </span>
        </label>
      </div>
      <div v-show="value.is_parking_required" class="col-sm-8">
        <input-component
          ref="parkingDescription"
          :key="value.is_parking_required"
          :value="value.parking_description"
          :required="true"
          type="text"
          name="parking-description"
          label="Parking description"
          :container-custom-classes="[
            setInputValidationClass('value.parking_description', true),
          ]"
          @formInput="handleParkingDescriptionInput"
        ></input-component>
      </div>
    </div>
    <text-editor
      id="deliveriesGeneralCommentsTextArea"
      :content="value.external_note"
      @update:text-editor-content="handleExternalNoteInput"
    ></text-editor>
  </div>
</template>

<script>
import InputComponent from '@components/form/form-input'
import TextEditor from '@components/text-editor/text-editor'
import { required, minLength } from 'vuelidate/lib/validators'
import FormMixin from '@mixins/forms-mixin'

export default {
  components: {
    InputComponent,
    TextEditor,
  },

  mixins: [FormMixin],

  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  validations: {
    value: {
      parking_description: {
        required,
        minLength: minLength(1),
      },
    },
  },

  methods: {
    handleParkingDescriptionInput(description) {
      this.handleInputUpdate(description, 'value.parking_description')
      this.$emit('input:parking-description', description)
    },

    handleExternalNoteInput(note) {
      this.$emit('input:external-note', note)
    },

    handleIsParkingRequired(e) {
      const isRequired = e.target.checked ? 1 : 0

      if (!isRequired) {
        this.$emit('input:parking-description', '')
      }

      this.$emit('input:is-parking-required', isRequired)
    },
  },
}
</script>
