<template>
  <div
    :class="[
      ' vd-darker-grey vd-margin-bottom-medium',
      !isMobileScreen
        ? 'vd-padding-medium vd-border-lighter-grey vd-border-radius-6 vd-border-width-2'
        : '',
    ]"
  >
    <div
      :class="[
        'vms-event-modal-content__info row no-gutters vd-flex-wrap',
        isMobileScreen ? 'vms-event-modal-content__info--mobile' : '',
      ]"
    >
      <div class="col-lg-6">
        <div :class="[!isMobileScreen ? 'vd-padding-right-large' : '']">
          <slot v-if="!isMobileScreen" name="event-specific-content"></slot>

          <div class="vd-darker-grey vd-padding-inline-small">
            <div class="vd-margin-top-small">
              <div class="vd-text-small vd-text-strong">Office</div>
              <template v-if="office">
                <input-clipboard-copy
                  v-if="isMobileDevice"
                  :input-value="office"
                ></input-clipboard-copy>

                <div v-else class="vd-display-flex vd-align-center">
                  {{ office }}

                  <clipboard-copy
                    success-message="copied!"
                    :copy-content="office | cleanString"
                  >
                    <i
                      class="vd-icon icon-copy vd-margin-left-medium vd-cursor-pointer vd-hover-light-blue"
                    ></i>
                  </clipboard-copy>
                </div>
              </template>
              <div v-else>--</div>
            </div>

            <div class="vd-margin-top-small">
              <div class="vd-text-small vd-text-strong">Project</div>
              <template v-if="projectName">
                <input-clipboard-copy
                  v-if="isMobileDevice"
                  :input-value="projectDetailsCopy"
                ></input-clipboard-copy>

                <div v-else class="vd-display-flex vd-align-center">
                  <router-link
                    v-if="projectId"
                    target="_blank"
                    class="vd-a vd-darker-grey"
                    :to="{
                      name: 'project.details.summary',
                      params: { projectId },
                    }"
                    >{{ projectId }} - {{ projectName }}</router-link
                  >

                  <clipboard-copy
                    success-message="copied!"
                    :copy-content="projectDetailsCopy | cleanString"
                  >
                    <i
                      class="vd-icon icon-copy vd-margin-left-medium vd-cursor-pointer vd-hover-light-blue"
                    ></i>
                  </clipboard-copy>
                </div>
              </template>
              <div v-else>--</div>
            </div>

            <div class="vd-margin-top-small">
              <div class="vd-text-small vd-text-strong">Job name</div>
              <template v-if="jobName">
                <input-clipboard-copy
                  v-if="isMobileDevice"
                  :input-value="jobName"
                ></input-clipboard-copy>

                <div v-else class="vd-display-flex vd-align-center">
                  {{ jobName }}

                  <clipboard-copy
                    success-message="copied!"
                    :copy-content="jobName | cleanString"
                  >
                    <i
                      class="vd-icon icon-copy vd-margin-left-medium vd-cursor-pointer vd-hover-light-blue"
                    ></i>
                  </clipboard-copy>
                </div>
              </template>
              <div v-else>--</div>
            </div>
          </div>
        </div>
      </div>

      <hr
        v-if="isMobileScreen"
        class="vd-flex-auto vd-margin-top-large vd-margin-inline-30"
      />

      <div class="col-lg-6">
        <slot v-if="isMobileScreen" name="event-specific-content"></slot>

        <div class="vd-padding-inline-small">
          <div class="vd-margin-top-small">
            <div
              class="vd-text-small vd-text-strong vd-margin-bottom-extra-small"
            >
              Relationship Manager
            </div>
            <staff-popover
              v-if="relationshipManager"
              class="vd-display-inline-block vd-cursor-pointer"
              :selected-user="relationshipManager"
              :tooltip-id="`relationship-manager-${relationshipManager.id}`"
              :label-avatar-font-size="30"
            ></staff-popover>
            <div v-else>--</div>
          </div>
          <div class="vd-margin-top-small">
            <div
              class="vd-text-small vd-text-strong vd-margin-bottom-extra-small"
            >
              Project Manager
            </div>
            <staff-popover
              v-if="projectManager"
              class="vd-display-inline-block vd-cursor-pointer"
              :selected-user="projectManager"
              :tooltip-id="`project-manager-${projectManager.id}`"
              :label-avatar-font-size="30"
            ></staff-popover>
            <div v-else>--</div>
          </div>
          <div class="vd-margin-top-small">
            <div
              class="vd-text-small vd-text-strong vd-margin-bottom-extra-small"
            >
              Project Team
            </div>
            <assigned-crew
              v-if="hasTeamMembers"
              :assigned-crew="teamMembers"
              :custom-avatar-font-size="30"
            ></assigned-crew>
            <div v-else>--</div>
          </div>
        </div>
      </div>

      <hr
        v-if="isMobileScreen"
        class="vd-flex-auto vd-margin-bottom-large vd-margin-inline-30"
      />
    </div>
  </div>
</template>

<script>
import StaffPopover from '@components/staff-popover/staff-popover'
import AssignedCrew from '@components/assigned-crew/assigned-crew'
import ClipboardCopy from '@components/clipboard-copy/clipboard-copy'
import InputClipboardCopy from '@components/input-clipboard-copy/input-clipboard-copy'
import FiltersMixin from '@mixins/filters-mixin'

import { mapGetters } from 'vuex'
import { isEmpty, filter, includes } from 'lodash'

export default {
  components: {
    StaffPopover,
    AssignedCrew,
    ClipboardCopy,
    InputClipboardCopy,
  },
  mixins: [FiltersMixin],

  props: {
    relationshipManager: {
      type: Object,
      required: false,
      default: () => {},
    },

    projectManager: {
      type: Object,
      required: false,
      default: () => null,
    },

    projectTeam: {
      type: Array,
      required: false,
      default: () => [],
    },

    office: {
      type: String,
      required: false,
      default: '',
    },

    projectId: {
      type: Number,
      required: false,
      default: null,
    },

    projectName: {
      type: String,
      required: false,
      default: '',
    },

    jobName: {
      type: String,
      required: false,
      default: '',
    },
  },

  computed: {
    ...mapGetters({
      isMobileDevice: 'common/isMobileDevice',
      isMobileScreen: 'common/isMobileScreen',
    }),

    hasTeamMembers() {
      return !isEmpty(this.teamMembers)
    },

    teamMembers() {
      return filter(this.projectTeam, (member) => {
        return !includes(
          [this.relationshipManager.id, this.projectManager.id],
          member.id
        )
      })
    },

    projectDetailsCopy() {
      return `${this.projectId} - ${this.projectName}`
    },
  },
}
</script>
