<template>
  <div class="vms-filming-details__content vd-padding-top-10">
    <div
      class="vms-filming-details__content--column vms-filming-details__map vd-padding-right-large"
    >
      <google-map :location="filmingDetail.location"></google-map>
    </div>

    <div
      class="vms-filming-details__content--column vms-filming-details__location vd-margin-right-medium"
    >
      <span class="vd-dark-grey vd-h5">Location</span>
      <div>
        <span>{{ filmingDetail.location }}</span>
      </div>
    </div>

    <div
      class="vms-filming-details__content--column vms-filming-details__production-date vd-margin-right-medium"
    >
      <span class="vd-dark-grey vd-h5">Date</span>
      <div v-if="filmingDetail.production_date">
        <span
          >{{ isOnHold ? '(TBC)' : '' }}
          {{
            filmingDetail.production_date | unixToFormat('dddd Do MMMM YYYY')
          }}</span
        >
        <br />
        <span>{{
          filmingDetail.production_date | unixToFormat('hh:mm a')
        }}</span>
      </div>
      <div v-else>
        <span>TBC</span>
      </div>
    </div>

    <div
      class="vms-filming-details__content--column vms-filming-details__shoot-duration vd-margin-right-medium"
    >
      <span class="vd-dark-grey vd-h5">Shoot Duration</span>
      <div>
        <span>
          {{ shootDurationLabel }}
        </span>
      </div>
    </div>

    <div
      class="vms-filming-details__content--column vms-filming-details__extras vd-margin-right-medium"
    >
      <span class="vd-dark-grey vd-h5">Additional Extras</span>
      <div v-if="hasExtras" class="vms-filming-details__extras--container">
        <div
          v-for="(
            productElementType, index
          ) in filmingDetail.product_element_types"
          :key="index"
          class="vd-margin-10 vd-margin-left-0"
        >
          <span
            class="vd-border-radius-50 vd-background-lightest-grey vd-padding-10"
          >
            {{ productElementType.name }}
          </span>
        </div>
      </div>

      <div v-else class="vms-filming-details__extras--container">
        <div class="vd-margin-10 vd-margin-left-0">
          <span
            class="vd-border-radius-50 vd-background-lightest-grey vd-padding-10 vd-a"
          >
            + Add Extra
          </span>
        </div>
      </div>
    </div>

    <div
      class="vms-filming-details__content--column vms-filming-details__pre-production vd-padding-right-medium"
    >
      <link-pre-production
        :linked-pre-productions="filmingDetail.pre_production_jobs"
        :linking-job-id="filmingDetail.id"
        :is-on-hold="isOnHold"
        @update:selected-pre-productions="$emit('add:pre-production', $event)"
        @remove:selected-pre-production="$emit('remove:pre-production', $event)"
      >
      </link-pre-production>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import LinkPreProduction from '@views/project/components/forms/components/link-pre-production'
import FiltersMixin from '@mixins/filters-mixin'
import GoogleMap from '@components/google-map/google-map'
import ShootDurationsConstants from '@configs/shoot-durations'

export default {
  components: {
    LinkPreProduction,
    GoogleMap,
  },

  mixins: [FiltersMixin],

  props: {
    filmingDetail: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },

    isOnHold: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      durationList: ShootDurationsConstants.shootDurations,
    }
  },

  computed: {
    hasExtras() {
      return !isEmpty(this.filmingDetail.product_element_types)
    },

    shootDurationLabel() {
      return this.durationList[this.filmingDetail.calendar_time]
    },
  },
}
</script>
