var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(_vm.isBulkAction)?_c('div',{staticClass:"vd-display-flex vd-align-center vd-padding-inline-small"},[_c('input',{staticClass:"vd-margin-right-small",attrs:{"id":"reassignUserCheckbox","type":"checkbox","name":"reassignUserCheckbox"},on:{"change":_vm.handleReassignUser}}),_c('label',{staticClass:"vd-margin-0 vd-display-flex",attrs:{"for":"reassignUserCheckbox"}},[_c('span',{staticClass:"vd-margin-right-small"},[_vm._v("Tick to re-assign")])])]):_vm._e(),(_vm.canShowAssignUserFields)?_c('div',{staticClass:"row no-gutters vd-align-center"},[_c('div',{staticClass:"col-md-6"},[_c('div',{class:[
          'vms-event-modal-content__field vd-padding-small',
          !_vm.assignToUser && _vm.assignToFreelancer
            ? 'vms-event-modal-content__field--inactive'
            : '' ]},[_c('v-autocomplete',{ref:"autocomplete",class:[
            'vd-field vms-event-modal-content__field',
            _vm.isAssignedUserInactive
              ? 'vd-input-error'
              : _vm.assignToUser
              ? 'vd-input-valid'
              : '' ],attrs:{"value":_vm.assignToUser,"disabled":_vm.disabled,"items":_vm.activeUsersList,"hide-selected":true,"menu-props":_vm.menuProps,"placeholder":"Select a team member (Leave this blank for unassigned)","persistent-placeholder":"","item-text":"full_name","item-value":"id","label":"Assign to","cache-items":"","clearable":""},on:{"change":function($event){return _vm.handleAssignToUserChange($event)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',[_vm._v("Assign to")]),(_vm.isAssignedUserInactive)?_c('text-popover',{staticClass:"vd-display-inline-block vd-align-middle",attrs:{"tooltip-icon":"vd-icon icon-circle-question","custom-tooltip-class":"vd-h5 vd-padding-left-extra-small vd-cursor-pointer","tooltip-id":"assignToTooltip","tooltip-text":"Inactive user selected"}}):_vm._e()]},proxy:true},{key:"selection",fn:function(ref){
          var item = ref.item;
return [(item)?_c('div',{staticClass:"vd-display-flex vd-align-center vd-margin-bottom-extra-small"},[_c('user-profile',{staticClass:"vd-margin-right-10",attrs:{"user":item,"custom-avatar-font-size":20}}),_c('span',[_vm._v(_vm._s(item.full_name))])],1):_vm._e()]}},{key:"item",fn:function(ref){
          var item = ref.item;
return [(item)?_c('div',{staticClass:"vd-display-flex vd-align-center vd-margin-bottom-extra-small"},[_c('user-profile',{staticClass:"vd-margin-right-small",attrs:{"user":item,"custom-avatar-font-size":30}}),_c('span',[_vm._v(_vm._s(item.full_name))])],1):_vm._e()]}},{key:"no-data",fn:function(){return [_c('div',[(!_vm.hasEventUsers)?_c('div',{staticClass:"vd-padding-block-small vd-padding-inline-large"},[_vm._v(" No results found ")]):_vm._e()])]},proxy:true}],null,false,868998987)})],1)]),_c('div',{staticClass:"col-md-1"},[_c('div',{staticClass:"vd-text-align-center"},[_vm._v("or")])]),_c('div',{staticClass:"col-md-5"},[_c('div',{class:[
          'vms-event-modal-content__field vd-padding-small',
          !_vm.assignToFreelancer && _vm.assignToUser
            ? 'vms-event-modal-content__field--inactive'
            : '' ]},[_c('input-component',{attrs:{"disabled":_vm.disabled,"value":_vm.assignToFreelancer,"container-custom-classes":[
            'vd-field',
            _vm.assignToFreelancer ? 'vd-input-valid' : '' ],"type":"text","name":"freelancer","label":"Enter a name for freelancer","placeholder":"Type a freelancer's name","autocomplete":"off"},on:{"formInput":function($event){return _vm.handleAssignToFreelancer($event)}}})],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }