<template>
  <default-modal
    :class="[
      'vms-event-modal show vd-text-align-left',
      isMobileScreen ? 'vms-modal--fullscreen' : 'vd-padding-inline-large',
    ]"
    :modal-dialog-class="
      isEventDetailsLoading
        ? 'vms-event-modal__dialog vms-event-modal__dialog--loading'
        : 'vms-event-modal__dialog'
    "
    content-class="vd-background-white"
    @click:backdrop="handleBackDropClick"
  >
    <div
      slot="modal-header"
      :style="eventColorStyle"
      class="vd-display-flex align-start vd-padding-medium"
    >
      <div>
        <div class="vd-h4 vd-black">
          {{ title }} <span v-if="isSelectedEventOnHold">(On Hold)</span>
        </div>
      </div>
      <i
        v-if="!isEventDetailsLoading"
        class="vd-icon icon-xmark vd-darker-grey vd-hover-black vd-margin-left-auto"
        @click="handleCancel"
      ></i>
    </div>

    <div slot="modal-body" class="modal-body">
      <default-loader v-if="isEventDetailsLoading"></default-loader>
      <slot v-else />
    </div>

    <div v-if="!isEventDetailsLoading" slot="modal-footer" class="modal-footer">
      <div class="vd-text-align-right">
        <button
          :disabled="isLoading"
          class="vd-btn-small vd-hover-light-blue vd-margin-right-extra-small"
          @click="handleCancel"
        >
          Cancel
        </button>
        <button
          :disabled="isSaveButtonDisabled"
          class="vd-btn-small vd-btn-blue"
          @click="handleSave"
        >
          {{ isLoading ? 'Loading...' : 'Save' }}
        </button>
      </div>
    </div>
  </default-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { includes, isEmpty } from 'lodash'
import DefaultModal from '@components/modal/default-modal'
import DefaultLoader from '@components/loaders/default-loader'
import VmsConstants from '@configs/vms-constants'
import EventTypesMixin from '@mixins/event-types-mixin'
import SweetAlert from '@plugins/sweet-alert'

export default {
  components: {
    DefaultModal,
    DefaultLoader,
  },

  mixins: [EventTypesMixin],

  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },

    calendarColor: {
      type: String,
      required: false,
      default: '',
    },

    isSaveDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },

    hasChanges: {
      type: Boolean,
      required: false,
      default: false,
    },

    canSaveAndTakeOffHold: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  provide() {
    return {
      // reactive provide/inject
      getEventColorStyle: () => this.eventColorStyle,
    }
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
      isEventDetailsLoading: 'calendar/isEventDetailsLoading',
      selectedEvent: 'calendar/selectedEvent',
      bulkActionSelectedEvents: 'calendar/bulkActionSelectedEvents',
      isSelectedEventOnHold: 'calendar/isSelectedEventOnHold',
    }),

    eventColorStyle() {
      return {
        // Add opacity 30% (0.3) to make it subtle and show black color text.
        backgroundColor: this.calendarColor
          ? `${this.calendarColor.toUpperCase()}4D`
          : '',
      }
    },

    isSaveBtnDisabledByGoogleOrBamboo() {
      // If it's bulk edit, we want to allow save.
      if (!isEmpty(this.bulkActionSelectedEvents)) {
        return false
      } else {
        return includes(
          [
            VmsConstants.eventTypes.EVENT_TYPE_ID_GOOGLE_CALENDAR_EVENT,
            VmsConstants.eventTypes.EVENT_TYPE_ID_BAMBOO_EVENT,
          ],
          this.selectedEvent?.event_type_id
        )
      }
    },

    isSaveButtonDisabled() {
      return (
        this.isSaveDisabled ||
        this.isLoading ||
        !this.hasChanges ||
        this.isSaveBtnDisabledByGoogleOrBamboo
      )
    },

    canShowSaveEventAlert() {
      return this.isSelectedEventOnHold && this.canSaveAndTakeOffHold
    },
  },

  methods: {
    ...mapActions({
      unholdJobEvent: 'calendar/unholdJobEvent',
    }),

    handleCancel() {
      if (this.isLoading || this.isEventDetailsLoading) {
        return
      }

      this.$emit('click:cancel')
    },

    handleSave() {
      if (this.canShowSaveEventAlert) {
        SweetAlert.fire({
          title: 'Save Event',
          html:
            '<div class="vd-margin-medium">This event is on hold, would you like to save and take off hold?</div>',
          showCancelButton: true,
          showConfirmButton: true,
          showCloseButton: true,
          cancelButtonText: 'Save only',
          cancelButtonColor: '#03aee1',
          confirmButtonText: 'Save and take off hold',
          confirmButtonColor: '#339900',
          heightAuto: false,
          scrollbarPadding: false,
        }).then((result) => {
          if (result.dismiss === 'cancel') {
            this.$emit('click:save')
          }

          if (result.isConfirmed) {
            const eventJobType = this.getJobTypeByEventTypeId(
              this.selectedEvent?.event_type?.id
            )

            this.unholdJobEvent({
              event_job_type: eventJobType,
              payload: {
                page: 1,
                notCancelable: true,
              },
              project_id: this.selectedEvent.project.id,
              order_job_id: this.selectedEvent.order_job_element.order_job_id,
            })

            this.$emit('click:save')
          }
        })
      } else {
        this.$emit('click:save')
      }
    },

    handleBackDropClick() {
      this.$emit('click:backdrop')
    },
  },
}
</script>

<style lang="scss">
@import '@styles/v2/components/modal/event-modal';
</style>
