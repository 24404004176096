<template>
  <div class="vms-filming-details__header">
    <strong class="vd-margin-right-small">{{ filmingDetail.name }}</strong>

    <div
      class="vms-filming-details__header--button-wrapper vd-margin-right-small"
    >
      <assigned-crew
        :assigned-crew="filmingDetail.order_job_assignees"
      ></assigned-crew>
    </div>

    <div class="vms-filming-details__header--button-wrapper">
      <button
        class="vms-filming-details__header--button vd-border-radius-10 vd-padding-left-small vd-padding-right-small vd-background-lightest-grey vd-dark-grey vd-border-lightest-grey vd-margin-right-small vd-padding-top-extra-small vd-padding-bottom-extra-small"
        @click="$emit('click:call-sheet')"
      >
        <i class="vd-icon icon-paper-phone vd-darker-grey"></i>
        <span
          class="vd-display-inline-block vd-text-small vd-margin-left-small"
        >
          Call sheet
        </span>
      </button>

      <span
        v-if="isHoldShootJobLoading"
        class="vd-padding-extra-small vd-dark-grey small"
      >
        Loading...
      </span>

      <button
        v-else
        :class="[
          'vms-filming-details__header--button vd-border-radius-10 vd-padding-left-small vd-padding-right-small vd-margin-right-small vd-padding-top-extra-small vd-padding-bottom-extra-small',
          isOnHold
            ? 'vd-background-cream vd-yellow vd-border-yellow'
            : ' vd-background-lightest-grey vd-dark-grey vd-border-lightest-grey',
        ]"
        @click="$emit('click:hold')"
      >
        <i class="vd-icon icon-circle-pause vd-darker-grey"></i>

        <span
          class="vd-display-inline-block vd-text-small vd-margin-left-small"
        >
          {{ isOnHold ? 'On Hold' : 'Hold' }}
        </span>

        <span
          v-if="isOnHold"
          class="vd-display-inline-block vd-margin-left-small vd-black"
          >&#10005;</span
        >
      </button>

      <button
        :class="[
          'vms-filming-details__header--button vd-position-relative vd-border-radius-10 vd-padding-inline-small vd-padding-block-extra-small vd-background-light-blue vd-white vd-border-light-blue vd-margin-right-small',
          filmingDetail.has_eod_notes ? 'vd-padding-right-medium' : '',
        ]"
        class=""
        @click="handleEodNotesClick"
      >
        <i class="vd-icon icon-chat"></i>
        <span
          :class="[
            'vd-display-inline-block vd-text-small vd-margin-left-small',
            filmingDetail.has_eod_notes ? 'vd-red-dot' : '',
          ]"
        >
          Notes
        </span>
      </button>

      <text-popover
        tooltip-icon="vd-icon-three-dots-circle"
        :tooltip-id="`divFilmingDetailsThreeDotMenu-${filmingDetail.id}`"
        tooltip-trigger="click blur"
        popover-custom-class="vms-filming-details__dropdown"
        custom-tooltip-class="vms-filming-details__dropdown--icon vd-margin-top-extra-small"
        :boundary-padding="1"
        :tooltip-placement="'rightbottom'"
      >
        <div slot="popover-body">
          <div>
            <div
              class="vms-dropdown__list vms-filming-details__dropdown--list vd-padding-block-small vd-padding-inline-medium"
              @click="$emit('click:edit')"
            >
              Update details
            </div>

            <div
              class="vms-dropdown__list vms-filming-details__dropdown--list vd-padding-block-small vd-padding-inline-medium"
              @click="deleteShootJob"
            >
              Delete
            </div>
          </div>
        </div>
      </text-popover>
    </div>
  </div>
</template>

<script>
import AssignedCrew from '@components/assigned-crew/assigned-crew'
import TextPopover from '@components/tooltips/text-popover'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    AssignedCrew,
    TextPopover,
  },

  props: {
    filmingDetail: {
      type: Object,
      required: true,
      default: () => ({}),
    },

    isOnHold: {
      type: Boolean,
      required: false,
      default: false,
    },

    isHoldShootJobLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
    }),
  },

  methods: {
    ...mapActions({
      deleteFilmingDetails: 'project/shoot/deleteFilmingDetails',
      setSelectedEodNoteTypeId: 'project/setSelectedEodNoteTypeId',
      toggleEodNotesModal: 'project/toggleEodNotesModal',
    }),

    deleteShootJob() {
      this.deleteFilmingDetails({
        project_id: this.project.id,
        shoot_job_id: this.filmingDetail.order_job_id,
        includes: null,
        name: this.filmingDetail.name,
      })
    },

    handleEodNotesClick() {
      this.setSelectedEodNoteTypeId(this.filmingDetail.order_job_id)
      this.toggleEodNotesModal(true)
    },
  },
}
</script>
