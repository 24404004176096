<template>
  <div>
    <div class="vms-call-sheet-list vd-background-lightest-grey">
      <call-sheet-contact-item
        v-for="(item, index) in value"
        :key="index"
        :value="item"
        @click:update="handleUpdate(item, $event)"
        @click:remove="handleRemove"
      ></call-sheet-contact-item>

      <div
        v-if="showNewItemForm"
        :class="[
          'vms-call-sheet-list__item vd-border-light-grey vd-position-relative',
          isMobileScreen ? 'vms-call-sheet-list__item--mobile' : '',
        ]"
      >
        <div
          class="vms-call-sheet-list__item--input-wrapper vms-call-sheet-list__item--role-contact vd-padding-left-small vd-padding-right-small vd-padding-top-small"
        >
          <div class="vms-call-sheet-list__item--label vd-dark-grey small">
            Role
          </div>
          <input
            v-model="inputModel.role"
            type="text"
            class="vms-call-sheet-list__item--input vd-border-light-grey vd-border-width-1 vd-border-radius-5 vd-display-block vd-background-white vd-padding-extra-small"
          />
        </div>

        <div
          class="vms-call-sheet-list__item--input-wrapper vms-call-sheet-list__item--name vd-padding-left-small vd-padding-right-small vd-padding-top-small"
        >
          <div class="vms-call-sheet-list__item--label vd-dark-grey small">
            Name
          </div>
          <input
            v-model="inputModel.name"
            type="text"
            class="vms-call-sheet-list__item--input vd-border-light-grey vd-border-width-1 vd-border-radius-5 vd-display-block vd-background-white vd-padding-extra-small"
          />
        </div>
        <div
          class="vms-call-sheet-list__item--input-wrapper vms-call-sheet-list__item--phone vd-padding-left-small vd-padding-right-small vd-padding-top-small"
        >
          <div class="vms-call-sheet-list__item--label vd-dark-grey small">
            Phone
          </div>
          <input
            v-model="inputModel.phone"
            type="text"
            class="vms-call-sheet-list__item--input vd-border-light-grey vd-border-width-1 vd-border-radius-5 vd-display-block vd-background-white vd-padding-extra-small"
          />
        </div>
        <div
          class="vms-call-sheet-list__item--input-wrapper vms-call-sheet-list__item--email vd-padding-left-small vd-padding-right-small vd-padding-top-small"
        >
          <div class="vms-call-sheet-list__item--label vd-dark-grey small">
            Email
          </div>
          <input
            v-model="inputModel.email"
            type="email"
            class="vms-call-sheet-list__item--input vd-border-light-grey vd-border-width-1 vd-border-radius-5 vd-display-block vd-background-white vd-padding-extra-small"
          />
        </div>

        <div
          class="vms-call-sheet-list__item--action-wrapper vd-padding-left-small vd-padding-right-small vd-padding-top-small vd-margin-top-large"
        >
          <button
            v-if="isFormValid"
            class="vms-call-sheet-list__item--action vd-icon-tick-circle vd-margin-right-small"
            @click="handleAdd"
          ></button>

          <button
            class="vms-call-sheet-list__item--action vd-icon-close-circle"
            @click="resetForm"
          ></button>
        </div>
      </div>
    </div>

    <div
      v-if="!showNewItemForm"
      class="vms-call-sheet-list__footer vd-text-align-right vd-padding-small"
    >
      <button class="vd-light-blue" @click="openNewForm">+ Add new</button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { assign } from 'lodash'
import { email } from 'vuelidate/lib/validators'
import FormMixin from '@mixins/forms-mixin'
import CallSheetContactItem from './filming-call-sheet-contact-item'

export default {
  components: {
    CallSheetContactItem,
  },

  mixins: [FormMixin],

  props: {
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  validations: {
    inputModel: {
      email: {
        email,
      },
    },
  },

  data() {
    return {
      showNewItemForm: false,
      inputModel: {
        name: '',
        phone: '',
        email: '',
        role: '',
      },
    }
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
    }),

    isFormValid() {
      return (
        !this.$v.$invalid &&
        (this.inputModel.name || this.inputModel.phone || this.inputModel.email)
      )
    },
  },

  methods: {
    handleAdd() {
      const newList = [...this.value, this.inputModel]
      this.$emit('input', newList)
      this.resetForm()
    },

    handleUpdate(item, newValue) {
      const newList = this.value.map((contact) => {
        if (contact === item) {
          contact = assign({}, newValue)
        }
        return contact
      })
      this.$emit('input', newList)
      this.resetForm()
    },

    handleRemove(item) {
      const newList = this.value.filter((i) => i !== item)

      this.$emit('input', newList)
    },

    resetForm() {
      this.inputModel = {
        name: '',
        phone: '',
        email: '',
        role: '',
      }
      this.showNewItemForm = false
    },

    openNewForm() {
      this.showNewItemForm = true
    },
  },
}
</script>
