<template>
  <div
    class="vms-content__empty vd-text-align-center vd-padding-right-20 vd-padding-left-20 vd-padding-bottom-20"
  >
    <img :class="imageCustomClass" :src="imageSrc" />

    <div>{{ description }}</div>
  </div>
</template>

<script>
export default {
  props: {
    imageSrc: {
      type: String,
      required: true,
      default: '',
    },

    description: {
      type: String,
      required: false,
      default: '',
    },

    imageCustomClass: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>
