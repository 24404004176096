<template>
  <div v-if="hasAssignedCrew" class="vms-assigned-crew">
    <div
      id="openCrewModalDiv"
      class="vms-assigned-crew__wrapper"
      @click="openCrewModal"
    >
      <user-profile
        v-for="(crew, index) in assignedCrewList"
        :key="index"
        :user="crew"
        :custom-avatar-font-size="customAvatarFontSize"
        class="vms-assigned-crew__profile"
      ></user-profile>

      <user-profile
        v-if="displayHiddenCrewCount"
        :user="{ full_name: `+${hiddenCrewCount}` }"
        :custom-avatar-font-size="customAvatarFontSize"
        custom-avatar-bg-colour="#ccc"
        class="vms-assigned-crew__profile"
      ></user-profile>
    </div>

    <default-modal
      v-if="showCrewModal"
      :class="['show', isMobileScreen ? 'vms-modal--fullscreen' : '']"
      modal-dialog-class="modal-md"
      content-class="vd-background-white"
    >
      <div slot="modal-header" class="modal-header">
        <div class="vd-h4 vd-black">{{ headerTitle }}</div>
        <span
          id="closeCrewModalSpan"
          class="vms-modal__header--close close vd-black"
          @click="closeCrewModal"
        >
          <span id="closeCrewModalSpan">x</span>
        </span>
      </div>

      <div slot="modal-body" class="modal-body">
        <div
          v-for="(crew, index) in assignedCrew"
          :key="index"
          :class="[
            'vms-assigned-crew__list',
            index > 0 ? 'vd-border-lightest-grey' : '',
          ]"
        >
          <div class="vd-padding-small">
            <user-profile
              :user="crew"
              :custom-avatar-font-size="35"
              class="vms-assigned-crew__profile"
            ></user-profile>
          </div>

          <div class="vd-padding-medium">
            {{ crew.full_name | truncateString(35) }}
            <div class="vd-dark-grey">
              <span>{{ crew.staff_title }}</span>
              <span v-if="crew.state"> | {{ crew.state.name }}</span>
            </div>
          </div>

          <div class="row vms-assigned-crew__actions vd-padding-small">
            <clipboard-copy
              container-class="vd-margin-right-small"
              custom-icon="vd-icon-mail vms-assigned-crew__icon"
              :copy-content="crew.email"
              :show-text="false"
              :show-toast-alert="true"
              :success-message="
                clipboardCopySuccessMessage({ type: 'email', crew })
              "
            ></clipboard-copy>
            <clipboard-copy
              container-class="vd-margin-right-small"
              custom-icon="vd-icon-call vms-assigned-crew__icon"
              :copy-content="crew.phone"
              :show-text="false"
              :show-toast-alert="true"
              :success-message="
                clipboardCopySuccessMessage({ type: 'phone', crew })
              "
            ></clipboard-copy>
          </div>
        </div>
      </div>
    </default-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UserProfile from '@components/user-profile/user-profile'
import DefaultModal from '@components/modal/default-modal'
import ClipboardCopy from '@components/clipboard-copy/clipboard-copy'
import FiltersMixin from '@mixins/filters-mixin'

import { size, slice } from 'lodash'

export default {
  components: {
    UserProfile,
    DefaultModal,
    ClipboardCopy,
  },
  mixins: [FiltersMixin],

  props: {
    assignedCrew: {
      type: Array,
      required: true,
      default: () => [],
    },

    headerTitle: {
      type: String,
      required: false,
      default: 'Assigned Crew',
    },

    maxUserDisplayLimit: {
      type: Number,
      required: false,
      default: 4,
    },

    customAvatarFontSize: {
      type: Number,
      required: false,
      default: 35,
    },
  },

  data() {
    return {
      showCrewModal: false,
    }
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
    }),

    hasAssignedCrew() {
      return !!size(this.assignedCrew)
    },

    displayHiddenCrewCount() {
      return this.maxUserDisplayLimit < size(this.assignedCrew)
    },

    hiddenCrewCount() {
      return size(this.assignedCrew) - this.maxUserDisplayLimit
    },

    assignedCrewList() {
      return this.displayHiddenCrewCount
        ? slice(this.assignedCrew, 0, this.maxUserDisplayLimit)
        : this.assignedCrew
    },
  },

  methods: {
    openCrewModal() {
      this.showCrewModal = true
    },

    closeCrewModal() {
      this.showCrewModal = false
    },

    clipboardCopySuccessMessage({ type, crew }) {
      return `Successfully copied the ${type} of ${crew.first_name} to your clipboard!`
    },
  },
}
</script>

<style lang="scss">
@import '@styles/v2/components/assigned-crew';
</style>
