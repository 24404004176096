<template>
  <a
    :href="googleMapLocation"
    target="_blank"
    title="View Google Map Location"
    rel="noreferrer noopener"
  >
    <img src="/images/icons/gmap.jpg" />
  </a>
</template>

<script>
export default {
  props: {
    location: {
      type: String,
      required: true,
    },
  },

  computed: {
    googleMapLocation() {
      return `https://maps.google.com/?q=${this.location}`
    },
  },
}
</script>
