var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vms-filming-details__header"},[_c('strong',{staticClass:"vd-margin-right-small"},[_vm._v(_vm._s(_vm.filmingDetail.name))]),_c('div',{staticClass:"vms-filming-details__header--button-wrapper vd-margin-right-small"},[_c('assigned-crew',{attrs:{"assigned-crew":_vm.filmingDetail.order_job_assignees}})],1),_c('div',{staticClass:"vms-filming-details__header--button-wrapper"},[_c('button',{staticClass:"vms-filming-details__header--button vd-border-radius-10 vd-padding-left-small vd-padding-right-small vd-background-lightest-grey vd-dark-grey vd-border-lightest-grey vd-margin-right-small vd-padding-top-extra-small vd-padding-bottom-extra-small",on:{"click":function($event){return _vm.$emit('click:call-sheet')}}},[_c('i',{staticClass:"vd-icon icon-paper-phone vd-darker-grey"}),_c('span',{staticClass:"vd-display-inline-block vd-text-small vd-margin-left-small"},[_vm._v(" Call sheet ")])]),(_vm.isHoldShootJobLoading)?_c('span',{staticClass:"vd-padding-extra-small vd-dark-grey small"},[_vm._v(" Loading... ")]):_c('button',{class:[
        'vms-filming-details__header--button vd-border-radius-10 vd-padding-left-small vd-padding-right-small vd-margin-right-small vd-padding-top-extra-small vd-padding-bottom-extra-small',
        _vm.isOnHold
          ? 'vd-background-cream vd-yellow vd-border-yellow'
          : ' vd-background-lightest-grey vd-dark-grey vd-border-lightest-grey' ],on:{"click":function($event){return _vm.$emit('click:hold')}}},[_c('i',{staticClass:"vd-icon icon-circle-pause vd-darker-grey"}),_c('span',{staticClass:"vd-display-inline-block vd-text-small vd-margin-left-small"},[_vm._v(" "+_vm._s(_vm.isOnHold ? 'On Hold' : 'Hold')+" ")]),(_vm.isOnHold)?_c('span',{staticClass:"vd-display-inline-block vd-margin-left-small vd-black"},[_vm._v("✕")]):_vm._e()]),_c('button',{class:[
        'vms-filming-details__header--button vd-position-relative vd-border-radius-10 vd-padding-inline-small vd-padding-block-extra-small vd-background-light-blue vd-white vd-border-light-blue vd-margin-right-small',
        _vm.filmingDetail.has_eod_notes ? 'vd-padding-right-medium' : '' ],on:{"click":_vm.handleEodNotesClick}},[_c('i',{staticClass:"vd-icon icon-chat"}),_c('span',{class:[
          'vd-display-inline-block vd-text-small vd-margin-left-small',
          _vm.filmingDetail.has_eod_notes ? 'vd-red-dot' : '' ]},[_vm._v(" Notes ")])]),_c('text-popover',{attrs:{"tooltip-icon":"vd-icon-three-dots-circle","tooltip-id":("divFilmingDetailsThreeDotMenu-" + (_vm.filmingDetail.id)),"tooltip-trigger":"click blur","popover-custom-class":"vms-filming-details__dropdown","custom-tooltip-class":"vms-filming-details__dropdown--icon vd-margin-top-extra-small","boundary-padding":1,"tooltip-placement":'rightbottom'}},[_c('div',{attrs:{"slot":"popover-body"},slot:"popover-body"},[_c('div',[_c('div',{staticClass:"vms-dropdown__list vms-filming-details__dropdown--list vd-padding-block-small vd-padding-inline-medium",on:{"click":function($event){return _vm.$emit('click:edit')}}},[_vm._v(" Update details ")]),_c('div',{staticClass:"vms-dropdown__list vms-filming-details__dropdown--list vd-padding-block-small vd-padding-inline-medium",on:{"click":_vm.deleteShootJob}},[_vm._v(" Delete ")])])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }