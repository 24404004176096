<template>
  <div>
    <div :class="['vd-field', containerCustomClasses]">
      <label>
        {{ productElementsSelectLabel }}
      </label>

      <select
        v-if="productElementsList && canUpdateExtras"
        :id="id"
        v-model="additionalExtras"
        class="vd-margin-bottom-small"
        required
      >
        <option
          v-if="availableExtras.length === 0"
          :value="null"
          disabled
          selected
        >
          No additional extras
        </option>
        <option v-else :value="null" disabled selected>
          {{ emptyOptionLabel }}
        </option>

        <option
          v-for="(availableExtra, index) in availableExtras"
          :key="index"
          :value="availableExtra.id"
          :disabled="
            isExtrasOptionDisabled(availableExtra.id) ||
            isExtrasInList(availableExtra.product_element_type.name)
          "
        >
          {{ availableExtra.product_element_type.name }}
        </option>
      </select>
    </div>

    <div v-if="hasExtras && isEditType" class="vms-product-elements--container">
      <div
        v-for="(productElementType, index) in selectedProductElements"
        :key="index"
        :class="[
          'vd-margin-extra-small vd-margin-left-0 vd-border-radius-50 vd-padding-block-extra-small vms-product-elements--selected',
          isExtraHovered(productElementType)
            ? 'vd-background-light-grey vd-padding-left-medium vd-padding-right-small'
            : 'vd-background-lighter-grey vd-padding-inline-large',
        ]"
        @click="removeSelectedExtra(productElementType)"
        @mouseover="handleExtraHover(productElementType)"
        @mouseleave="handleExtraHover(null)"
      >
        <span>
          {{ productElementType.name }}
        </span>

        <span
          v-show="isExtraHovered(productElementType)"
          class="vd-icon-close-circle vms-product-elements--remove vd-margin-left-small"
        ></span>
      </div>
    </div>

    <div v-if="selectedExtras.length" class="vms-product-elements--container">
      <div
        v-for="(selectedExtra, index) in selectedExtras"
        :key="index"
        :class="[
          'vd-margin-extra-small vd-margin-left-0 vd-border-radius-50 vd-padding-block-extra-small vms-product-elements--selected',
          isExtraHovered(selectedExtra)
            ? 'vd-background-light-grey vd-padding-left-medium vd-padding-right-small'
            : 'vd-background-lighter-grey vd-padding-inline-large',
        ]"
        @click="removeSelectedExtra(selectedExtra)"
        @mouseover="handleExtraHover(selectedExtra)"
        @mouseleave="handleExtraHover(null)"
      >
        <span>
          {{ getSelectedExtraName(selectedExtra) }}
        </span>

        <span
          v-show="isExtraHovered(selectedExtra)"
          class="vd-icon-close-circle vms-product-elements--remove vd-margin-left-small"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  isEmpty,
  includes,
  find,
  filter,
  without,
  cloneDeep,
  isNil,
} from 'lodash'

export default {
  props: {
    productElementsSelectLabel: {
      type: String,
      required: false,
      default: 'Additional Extras',
    },

    emptyOptionLabel: {
      type: String,
      required: false,
      default: 'Add extras',
    },

    id: {
      type: String,
      required: true,
    },

    isEditType: {
      type: Boolean,
      required: true,
      default: false,
    },

    hoveredExtra: {
      type: [Number, Object],
      required: false,
      default: null,
    },

    availableExtras: {
      type: Array,
      required: true,
      default: () => {
        return []
      },
    },

    productElementsList: {
      type: Array,
      required: true,
      default: () => {
        return []
      },
    },

    selectedProductElements: {
      type: Array,
      required: true,
      default: () => {
        return []
      },
    },

    hasExtras: {
      type: Boolean,
      required: true,
      default: false,
    },

    canUpdateExtras: {
      type: Boolean,
      required: false,
      default: true,
    },

    containerCustomClasses: {
      type: [Array, String],
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      additionalExtras: null,
      selectedExtras: [],
      defaultSelectedProductElements: [],
    }
  },

  computed: {
    extras() {
      return this.availableExtras?.map((extra) => extra.product_element_type)
    },
  },

  watch: {
    additionalExtras(val) {
      if (val !== null && this.canUpdateExtras) {
        // Check if the selected product element is in the available extras.
        const selectedAvailableExtra = find(
          this.availableExtras,
          (availableExtra) => {
            return availableExtra.id === val
          }
        )

        // Check if the selected extra is in the default selected product elements (coming from the database).
        if (!isNil(selectedAvailableExtra)) {
          const selectedProductElement = find(
            this.defaultSelectedProductElements,
            (defaultSelectedProductElement) => {
              return (
                selectedAvailableExtra.product_element_type.id ===
                defaultSelectedProductElement.id
              )
            }
          )

          /**
           * If the selected extra is in default selected product elements
           * then just push it back to the selectedProductElements prop.
           * Else just push it into selectedExtras.
           */
          if (!isNil(selectedProductElement)) {
            this.selectedProductElements.push(selectedProductElement)
          } else {
            this.selectedExtras.push(val)
            this.$emit('update:selected-extras', this.selectedExtras)
          }
        }

        this.additionalExtras = null
      }
    },
  },

  mounted() {
    /**
     * Retain the original selected product elements coming from the database.
     * This data will be used to compare if the selected extras belong to the original
     * data or not and if it belongs update will be done on original props selectedProductElements.
     */
    this.defaultSelectedProductElements = cloneDeep(
      this.selectedProductElements
    )
  },

  methods: {
    handleExtraHover(productElement) {
      this.$emit('hover:selected-extras', productElement)
    },

    removeSelectedExtra(productElement) {
      if (this.canUpdateExtras) {
        this.selectedExtras = without(this.selectedExtras, productElement)
        this.$emit('update:selected-extras', this.selectedExtras)
        this.$emit('hover:remove-selected-extra', productElement)

        // to re-render the v-for without reloading the whole page
        this.$forceUpdate()
      }
    },

    /**
     * Return the selected product element type name
     *
     * @param {Number} productElementId - The product element ID for checking the name
     *
     * @returns {String} Name of the selected extra. Returns an empty string when no extras found
     */
    getSelectedExtraName(productElementId) {
      let extras = this.productElementsList

      if (this.isEditType) {
        extras = this.availableExtras
      }

      const selectedExtra = filter(extras, { id: productElementId })

      return !isEmpty(selectedExtra)
        ? selectedExtra[0].product_element_type.name
        : ''
    },

    isExtraHovered(productElementId) {
      return this.hoveredExtra === productElementId && this.canUpdateExtras
    },

    isExtrasOptionDisabled(productElementTypeId) {
      return includes(this.selectedExtras, productElementTypeId)
    },

    isExtrasInList(productElementTypeName) {
      if (this.selectedProductElements) {
        return (
          find(this.selectedProductElements, {
            name: productElementTypeName,
          }) !== undefined
        )
      }

      return false
    },
  },
}
</script>

<style lang="scss">
@import '@styles/views/project/product-elements';
</style>
