<template>
  <v-app>
    <div class="row no-gutters vd-align-center">
      <div class="col-lg-6">
        <div
          :class="[
            'vd-field vd-padding-block-medium vd-padding-inline-small',
            startDate ? 'vd-input-valid' : '',
            isRequired ? 'vd-required' : '',
          ]"
        >
          <label class="vd-position-relative">Start date</label>
          <flat-pickr
            id="startDate"
            :value="startDate"
            :config="startDateConfig"
            :disabled="disabled"
            placeholder="Select date"
            name="startDate"
            @on-change="handleStartDateChange($event)"
          >
          </flat-pickr>
        </div>
      </div>

      <div v-if="isAllDayEvent" class="col-lg-6">
        <div
          :class="[
            'vd-field vd-padding-block-medium vd-padding-inline-small',
            endDate ? 'vd-input-valid' : '',
            isRequired ? 'vd-required' : '',
          ]"
        >
          <label class="vd-position-relative">End date</label>
          <flat-pickr
            id="endDate"
            :value="endDate"
            :config="endDateConfig"
            :disabled="disabled"
            placeholder="Select date"
            name="startDate"
            @on-change="handleEndDateChange($event)"
          >
          </flat-pickr>
        </div>
      </div>

      <template v-else>
        <div class="col-md-8 col-lg-5">
          <div class="vd-display-flex vd-align-center">
            <div
              class="vd-flex-auto vd-padding-block-medium vd-padding-inline-small"
            >
              <v-autocomplete
                id="startTime"
                :value="startTime"
                :items="startTimeOptions"
                :disabled="disabled"
                :menu-props="menuProps"
                :class="[
                  'vd-field',
                  startTime ? 'vd-input-valid' : '',
                  isRequired ? 'vd-required' : '',
                ]"
                placeholder="Select time"
                persistent-placeholder
                label="Start time:"
                item-text="text"
                item-value="value"
                no-data-text="No results to display"
                @change="handleStartTimeChange($event)"
              >
                <template v-slot:selection="{ item }">
                  {{ item.text }}
                </template>
              </v-autocomplete>
            </div>

            <span class="vd-margin-right-small">to</span>

            <div
              class="vd-input-valid vd-flex-auto vd-padding-block-medium vd-padding-inline-small"
            >
              <v-autocomplete
                id="endTime"
                :value="endTime"
                :items="endTimeOptions"
                :disabled="disabled"
                :menu-props="menuProps"
                :class="[
                  'vd-field',
                  endTime ? 'vd-input-valid' : '',
                  isRequired ? 'vd-required' : '',
                ]"
                placeholder="Select time"
                persistent-placeholder
                label="End time:"
                item-text="text"
                item-value="value"
                no-data-text="No results to display"
                @change="handleEndTimeChange($event)"
              >
                <template v-slot:selection="{ item }">
                  {{ item.text }}
                </template>
                <template v-slot:item="{ item }">
                  <span>
                    {{ item.text }}
                    {{ getTimeDifference(item.value) | addParenthesis }}
                  </span>
                </template>
              </v-autocomplete>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="vd-field vd-padding-small">
            <label>Duration</label>
            <div class="">{{ durationText || 0 }}</div>
          </div>
        </div>
      </template>
    </div>

    <div v-if="hasAllDayOption" class="vd-padding-small">
      <label for="isAllDayCheckbox" class="vd-cursor-pointer">
        <span class="vd-margin-right-small vd-h5 vd-darker-grey"
          >All day event?</span
        >
        <input
          id="isAllDayCheckbox"
          :checked="isAllDayEvent"
          :disabled="disabled"
          type="checkbox"
          class="vd-align-middle"
          @change="$emit('update:isAllDayEvent', $event.target.checked)"
        />
      </label>
    </div>
  </v-app>
</template>

<script>
import FlatPickr from 'vue-flatpickr-component'
import momentTz from 'moment-timezone'
import Vuetify from 'vuetify'
import { some } from 'lodash'

export default {
  vuetify: new Vuetify(),

  components: {
    FlatPickr,
  },

  filters: {
    addParenthesis(value) {
      return value ? `(${value})` : ''
    },
  },

  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    hasAllDayOption: {
      type: Boolean,
      required: false,
      default: false,
    },

    startDate: {
      type: String,
      required: false,
      default: '',
    },

    endDate: {
      type: String,
      required: false,
      default: '',
    },

    startTime: {
      type: String,
      required: false,
      default: '',
    },

    endTime: {
      type: String,
      required: false,
      default: '',
    },

    duration: {
      type: Number,
      required: false,
      default: 0,
    },

    isAllDayEvent: {
      type: Boolean,
      required: false,
      default: false,
    },

    isRequired: {
      type: Boolean,
      required: false,
      default: true,
    },

    isBulkAction: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      menuProps: {
        auto: true,
        closeOnClick: true,
        offsetY: true,
      },
    }
  },

  computed: {
    startDateConfig() {
      return {
        altInput: true,
        allowInput: true,
        altFormat: 'dddd, D MMMM YYYY',
        dateFormat: 'YYYY-MM-DD',
        parseDate: (dateString, format) => {
          // ignore default timezone
          return momentTz.tz(dateString, format, '').toDate()
        },
        formatDate: (date, format) => {
          return momentTz(date).format(format)
        },
        disableMobile: 'true',
      }
    },

    endDateConfig() {
      return {
        altInput: true,
        allowInput: true,
        altFormat: 'dddd, D MMMM YYYY',
        dateFormat: 'YYYY-MM-DD',
        minDate: momentTz(this.startDate).format('YYYY-MM-DD'),
        parseDate: (dateString, format) => {
          // ignore default timezone
          return momentTz.tz(dateString, format, '').toDate()
        },
        formatDate: (date, format) => {
          return momentTz(date).format(format)
        },
        disableMobile: true,
      }
    },

    startTimeOptions() {
      // start at 12:00am to 11:30pm
      return this.generateTimeOptions('00:00:00', '23:30:00')
    },

    endTimeOptions() {
      // start at startTime + 15 minutes to 12:00am next day
      const fromTime = this.startTime
        ? momentTz(this.startTime, 'HH:mm:ss')
            .add(15, 'minutes')
            .format('HH:mm:ss')
        : '00:30:00'
      return this.generateTimeOptions(fromTime, '24:00:00')
    },

    durationText() {
      return this.endTime ? this.getTimeDifference(this.endTime) : ''
    },

    getCurrentStartTime() {
      // return start time nearest to startTimeOptions().
      // e.g if current time is 10:08 it will return 10.15
      const currentTime = momentTz().seconds(0)
      const minutes = currentTime.minutes()

      return currentTime
        .minutes(Math.ceil(minutes / 15) * 15)
        .format('HH:mm:ss')
    },
  },

  watch: {
    durationText() {
      const duration = this.getDuration(this.startTime, this.endTime)
      this.$emit('update:duration', duration.asMinutes())
    },
  },

  mounted() {
    this.$nextTick(() => {
      if (!this.startTime && !this.isAllDayEvent && !this.isBulkAction) {
        this.$emit('update:startTime', this.getCurrentStartTime)
      }
    })
  },

  methods: {
    handleStartDateChange(event) {
      if (!event[0]) {
        this.$emit('update:startDate', '')
        return
      }

      const dateValue = event[0]
      const formattedDate = momentTz(dateValue, 'dddd, D MMMM YYYY').format(
        'YYYY-MM-DD'
      )

      this.$emit('update:startDate', formattedDate)
    },

    handleEndDateChange(event) {
      if (!event[0]) {
        this.$emit('update:endDate', '')
        return
      }

      const dateValue = event[0]
      const formattedDate = momentTz(dateValue, 'dddd, D MMMM YYYY').format(
        'YYYY-MM-DD'
      )
      this.$emit('update:endDate', formattedDate)
    },

    handleStartTimeChange(startTime) {
      this.$emit('update:startTime', startTime)

      // reset endTime if value is not in endTimeOptions
      this.$nextTick(() => {
        if (!some(this.endTimeOptions, { value: this.endTime })) {
          this.$emit('update:endTime', this.endTimeOptions[0].value)
        }
      })
    },

    handleEndTimeChange(endTime) {
      this.$emit('update:endTime', endTime)
    },

    generateTimeOptions(fromTime, maxTime, minutesInterval = 15) {
      if (!fromTime) {
        return []
      }

      const timeOptions = []
      const startTime = momentTz(fromTime, 'HH:mm:ss')
      const endTime = momentTz(maxTime, 'HH:mm:ss')
      while (startTime.isSameOrBefore(endTime)) {
        timeOptions.push({
          text: startTime.format('h:mm a'),
          value: startTime.format('HH:mm:ss'),
        })
        startTime.add(minutesInterval, 'minutes')
      }

      return timeOptions
    },

    getDuration(from, to) {
      const fromTime = momentTz(from, 'HH:mm:ss')
      // add 1 day to endTime if endTime is 12:00am
      const toTime =
        to === '00:00:00'
          ? momentTz(to, 'HH:mm:ss').add(1, 'day')
          : momentTz(to, 'HH:mm:ss')

      const difference = momentTz(toTime).diff(fromTime)
      return momentTz.duration(difference)
    },

    getTimeDifference(endTime) {
      if (!this.startTime) {
        return ''
      }

      const duration = this.getDuration(this.startTime, endTime)

      // return duration for display
      if (duration.asMinutes() <= 0) {
        return ''
      }
      if (duration.asHours() < 1) {
        return `${duration.asMinutes()} mins`
      }
      if (duration.asHours() === 1) {
        return `${duration.asHours()} hour`
      }
      return `${duration.asHours()} hours`
    },
  },
}
</script>

<style lang="scss">
@import '@styles/plugins/vuetify';
@import '~flatpickr/dist/flatpickr.css';
@import '@styles/plugins/vue-flatpickr.scss';
</style>
