<template>
  <div>
    <div class="vd-margin-bottom-small vd-h4">Shoot Contacts</div>
    <call-sheet-contact-item
      v-for="(item, index) in callSheetData.project_contacts"
      :key="index"
      :value="item"
      :is-read-only="true"
      default-role="Project contact"
      class="vd-background-lightest-grey"
    ></call-sheet-contact-item>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CallSheetContactItem from './filming-call-sheet-contact-item'

export default {
  components: {
    CallSheetContactItem,
  },

  computed: {
    ...mapGetters({
      callSheetData: 'project/shoot/callSheetData',
    }),
  },
}
</script>
