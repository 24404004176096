var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'vms-call-sheet-form',
    _vm.isMobileScreen
      ? 'vms-call-sheet-form__mobile vd-background-white vd-margin-0'
      : 'vd-background-lightest-grey' ]},[_c('div',{class:[
      'vms-call-sheet-form__header vd-padding-medium',
      _vm.isMobileScreen
        ? 'vms-call-sheet-form__header--mobile vd-background-white vd-border-light-grey vd-h3'
        : 'vd-padding-bottom-0' ]},[_c('span',{staticClass:"vd-icon-preprod-call vd-margin-right-small vms-call-sheet-form__icon"}),_c('span',{staticClass:"vd-h4"},[_vm._v("Call sheet")]),(_vm.isMobileScreen)?_c('div',{staticClass:"vms-call-sheet-form__close",on:{"click":_vm.cancelForm}},[_c('span',{staticClass:"vd-icon-close vms-call-sheet-form__close--icon"})]):_vm._e()]),(_vm.showLoading)?_c('div',{staticClass:"vd-padding-large vd-margin-top-large vd-text-align-center"},[_c('default-loader')],1):[_c('div',{class:[
        'vms-call-sheet-form__content',
        _vm.isSmallScreen
          ? 'vms-call-sheet-form__content--small vd-padding-medium'
          : 'vd-padding-large' ]},[(!_vm.hasCustomCallSheet)?[_c('filming-call-sheet-events'),_c('filming-call-sheet-schedule',{model:{value:(_vm.formModel.shoot_schedule),callback:function ($$v) {_vm.$set(_vm.formModel, "shoot_schedule", $$v)},expression:"formModel.shoot_schedule"}}),_c('div',[_c('filming-call-sheet-project-contacts'),_c('filming-call-sheet-site-contact',{model:{value:(_vm.formModel.site_contact),callback:function ($$v) {_vm.$set(_vm.formModel, "site_contact", $$v)},expression:"formModel.site_contact"}}),_c('filming-call-sheet-other-contacts',{model:{value:(_vm.formModel.additional_contacts),callback:function ($$v) {_vm.$set(_vm.formModel, "additional_contacts", $$v)},expression:"formModel.additional_contacts"}})],1),_c('filming-call-sheet-remarks',{attrs:{"value":_vm.formModel},on:{"input:is-parking-required":function($event){_vm.formModel.is_parking_required = $event},"input:parking-description":function($event){_vm.formModel.parking_description = $event},"input:external-note":function($event){_vm.formModel.external_note = $event}}})]:_c('div',[_c('p',{staticClass:"vd-margin-bottom-0"},[_vm._v(" Automatic call sheet has been stopped and custom link has been provided. ")]),_c('a',{attrs:{"href":_vm.callSheetUrl,"target":"_blank"}},[_vm._v(_vm._s(_vm.callSheetUrl))])])],2),_c('div',{class:[
        'vms-call-sheet-form__actions vd-padding-medium vd-padding-left-large vd-padding-right-large',
        _vm.isMobileScreen
          ? 'vms-call-sheet-form__actions--mobile vd-background-lightest-grey vd-border-light-grey'
          : 'vd-border-white' ]},[(!_vm.callSheetLoading)?_c('button',{staticClass:"vd-btn-small vd-padding-small",on:{"click":_vm.cancelForm}},[_vm._v(" Cancel ")]):_vm._e(),_c('button',{staticClass:"vd-background-white vd-btn-small vd-margin-right-small",on:{"click":_vm.toggleManualCallSheetModal}},[_c('span',{staticClass:"vms-call-sheet-form__icon vd-icon-stop vd-margin-top-extra-small"})]),_c('a',{staticClass:"vd-background-white vd-btn-small vd-margin-right-small",attrs:{"href":_vm.callSheetUrl,"target":"_blank"}},[_c('span',{staticClass:"vms-call-sheet-form__icon vd-icon-eye vd-margin-top-extra-small"})]),_c('button',{staticClass:"vd-btn-blue vd-btn-small vd-padding-small",attrs:{"disabled":_vm.disableSave},on:{"click":_vm.saveForm}},[_vm._v(" "+_vm._s(_vm.callSheetLoading ? 'Loading...' : 'Save')+" ")])])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }