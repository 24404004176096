<template>
  <div class="vd-padding-small">
    <button
      v-if="!showGeneralComments"
      class="vd-text-small vd-padding-0 vd-border-dark-grey vd-border-width-0 vd-border-bottom-width-1 vd-dark-grey vd-hover-light-blue vd-hover-border-light-blue"
      @click="showGeneralComments = true"
    >
      Click to add/show general comments
    </button>
    <div v-else>
      <div class="vd-display-flex vd-justify-end vd-position-relative">
        <button
          class="vd-text-small vd-padding-0 vd-border-dark-grey vd-border-width-0 vd-border-bottom-width-1 vd-dark-grey vd-hover-light-blue vd-hover-border-light-blue vd-position-absolute"
          @click="showGeneralComments = false"
        >
          Hide comments
        </button>
      </div>
      <text-editor
        id="calendarEventGeneralCommentsEditor"
        :class="['vd-field', generalComments ? 'vd-input-valid' : '']"
        :content="generalComments"
        :disabled="disabled"
        label="General Comments"
        @update:text-editor-content="handleGeneralCommentsUpdate"
      ></text-editor>
    </div>
  </div>
</template>

<script>
import TextEditor from '@components/text-editor/text-editor'
import { isEmpty } from 'lodash'

export default {
  components: {
    TextEditor,
  },

  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    generalComments: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      showGeneralComments: false,
    }
  },

  mounted() {
    this.showGeneralComments = !isEmpty(this.generalComments)
  },

  methods: {
    handleGeneralCommentsUpdate(generalComments) {
      this.$emit('update:generalComments', generalComments)
    },
  },
}
</script>
