export default Object.freeze({
  states: [
    {
      id: 1,
      name: 'VIC',
    },
    {
      id: 2,
      name: 'NSW',
    },
    {
      id: 3,
      name: 'QLD',
    },
    {
      id: 4,
      name: 'SA',
    },
    {
      id: 5,
      name: 'WA',
    },
    {
      id: 6,
      name: 'ACT',
    },
    {
      id: 7,
      name: 'TAS',
    },
    {
      id: 8,
      name: 'NT',
    },
    {
      id: 9,
      name: 'International',
    },
  ],
})
