<template>
  <div
    class="vms-event-modal-actions vd-position-relative vd-display-flex vd-justify-end vd-align-center vd-margin-bottom-small"
  >
    <template v-if="!isMobileScreen">
      <events-header-actions-common
        :can-show-put-on-hold-btn="canShowPutOnHoldBtn"
        :is-bulk-action="isBulkAction"
        :custom-button-class="actionClass"
        :can-show-notes="canShowNotes"
        @click:notes="openEodNotesModal()"
      ></events-header-actions-common>
    </template>

    <div
      v-if="showActionsDropdown || isMobileScreen"
      v-click-outside="closeActionsDropdown"
      class="vd-position-relative vd-margin-left-medium"
    >
      <button
        class="vd-btn-small vd-border-dark-grey"
        :disabled="isEventFormLoading"
        @click="showActions = !showActions"
      >
        Actions
        <i
          class="vd-icon icon-chevron-down vd-margin-left-extra-small vd-text-extra-small"
        ></i>
      </button>
      <div
        v-if="showActions"
        class="vms-event-modal-actions__dropdown vd-position-absolute vd-background-white vd-box-shadow vd-padding-block-extra-small vd-border-radius-6"
      >
        <template v-if="isMobileScreen">
          <events-header-actions-common
            :can-show-put-on-hold-btn="canShowPutOnHoldBtn"
            :is-bulk-action="isBulkAction"
            :custom-button-class="dropdownItemClass"
            :can-show-notes="canShowNotes"
            @click:notes="openEodNotesModal()"
          ></events-header-actions-common>
        </template>

        <template v-if="!isBulkAction">
          <router-link
            v-if="project"
            target="_blank"
            :class="dropdownItemClass"
            :to="{
              name: 'project.details.summary',
              params: { projectId: project.id },
            }"
          >
            View project
          </router-link>

          <harvest-component
            v-if="project"
            :project="project"
            :project-first-production-order="projectFirstProductionOrder"
          >
            <button slot="body" :class="dropdownItemClass">
              Harvest timesheet
            </button>
          </harvest-component>

          <button
            v-if="canShowUploadVideoBtn"
            :class="dropdownItemClass"
            @click="handleClickUploadVideo"
          >
            Upload video
          </button>

          <button
            v-if="videoInternalUrl"
            :class="dropdownItemClass"
            @click="handleClickInternalReview"
          >
            Internal review
          </button>

          <button
            v-if="canShowChangesBtn && canDisplayChangesOption"
            :class="dropdownItemClass"
            @click="handleClickChanges"
          >
            Changes
          </button>

          <button
            v-if="videoUrl"
            :class="dropdownItemClass"
            @click="handleClickViewVideo"
          >
            View video
          </button>
        </template>

        <slot name="dropdown-actions"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { isNil } from 'lodash'
import ClickOutside from 'vue-click-outside'
import EventsHeaderActionsCommon from '@components/modal/events/components/events-header-actions/components/events-header-actions-common'
import HarvestComponent from '@components/harvest/harvest'
import AppConfig from '@configs/app-config'
import VmsConstants from '@configs/vms-constants'

export default {
  components: {
    EventsHeaderActionsCommon,
    HarvestComponent,
  },

  directives: {
    ClickOutside,
  },

  props: {
    isBulkAction: {
      type: Boolean,
      default: false,
    },

    showActionsDropdown: {
      type: Boolean,
      required: false,
      default: true,
    },

    canShowPutOnHoldBtn: {
      type: Boolean,
      required: false,
      default: true,
    },

    canShowUploadVideoBtn: {
      type: Boolean,
      required: false,
      default: false,
    },

    canShowChangesBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      showActions: false,
    }
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
      selectedEvent: 'calendar/selectedEvent',
      isEventFormLoading: 'calendar/isEventFormLoading',
    }),

    project() {
      return this.selectedEvent.project
    },

    actionClass() {
      return 'vms-event-modal-actions__btn vd-margin-left-large vd-border-transparent vd-border-width-0 vd-border-bottom-width-2 vd-hover-border-light-blue'
    },

    dropdownItemClass() {
      return 'vd-display-block vd-text-align-left vd-text-nowrap vd-padding-inline-medium vd-padding-block-small vd-hover-background-lightest-grey vd-full-width vd-black vd-hover-black vd-text-decoration-none'
    },

    orderJobId() {
      return this.selectedEvent?.order_job_element?.order_job_id
    },

    canDisplayChangesOption() {
      return (
        this.selectedEvent.order_job_element.order_job.status_id !==
          VmsConstants.orderJobStatuses.ORDER_JOB_BOOKED &&
        this.selectedEvent.order_job_element.order_job.status_id !==
          VmsConstants.orderJobStatuses.ORDER_JOB_APPROVED &&
        this.selectedEvent.order_job_element.order_job.status_id !==
          VmsConstants.orderJobStatuses.ORDER_JOB_ON_HOLD
      )
    },

    uploadVideoPageUrl() {
      return this.selectedEvent.video
        ? `${AppConfig.vmsApiUrl}/videos/edit/${this.selectedEvent.video.id}`
        : `${AppConfig.vmsApiUrl}/videos/add/${this.selectedEvent.order_job_element.order_job.id}`
    },

    videoUrl() {
      return this.selectedEvent.video?.video_landing_url || ''
    },

    videoInternalUrl() {
      return this.selectedEvent.video?.video_landing_internal_url || ''
    },

    orderJobChangePageUrl() {
      return `${AppConfig.vmsApiUrl}/order-job-changes/${this.selectedEvent.order_job_element.order_job.id}/index`
    },

    projectFirstProductionOrder() {
      return this.selectedEvent.project_first_production_order
        ? this.selectedEvent.project_first_production_order
        : null
    },

    canShowNotes() {
      return !isNil(this.orderJobId)
    },
  },

  methods: {
    ...mapActions({
      setEodNotesSelectedProject: 'project/setEodNotesSelectedProject',
      setSelectedEodNoteTypeId: 'project/setSelectedEodNoteTypeId',
      toggleEodNotesModal: 'project/toggleEodNotesModal',
      setShowEventsModal: 'calendar/setShowEventsModal',
    }),

    closeActionsDropdown() {
      this.showActions = false
    },

    openEodNotesModal() {
      const projectObj = {
        id: this.selectedEvent.project.id,
        name: this.selectedEvent.project.name,
      }

      this.setEodNotesSelectedProject(projectObj)
      this.setSelectedEodNoteTypeId(this.orderJobId)
      this.toggleEodNotesModal(true)
      this.setShowEventsModal(false)
    },

    handleClickUploadVideo() {
      window.open(this.uploadVideoPageUrl, '_blank')
    },

    handleClickInternalReview() {
      window.open(this.videoInternalUrl, '_blank')
    },

    handleClickChanges() {
      window.open(this.orderJobChangePageUrl, '_blank')
    },

    handleClickViewVideo() {
      window.open(this.videoUrl, '_blank')
    },
  },
}
</script>
