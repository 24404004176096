<template>
  <div class="vms-call-sheet-list__wrapper">
    <div class="vd-margin-bottom-small vd-h4">Shoot Schedule</div>

    <div
      v-if="isEmptyList && !showNewItemForm"
      class="small vd-dark-grey vd-text-align-center vd-margin-top-large vd-margin-bottom-large"
    >
      No shoot scheduled yet, Please schedule or move off hold
    </div>

    <div class="vms-call-sheet-list vd-background-lightest-grey">
      <draggable
        :value="value"
        handle=".handle"
        ghost-class="vms-ghost"
        :animation="200"
        :set-data="modifyDragItem"
        :disabled="onEditIndex !== undefined"
        :move="handleMove"
        @input="handleListChange($event)"
      >
        <div
          v-for="(item, index) in value"
          :key="index"
          :class="[
            'vms-call-sheet-list__item vd-border-light-grey vd-position-relative',
            isMobileScreen ? 'vms-call-sheet-list__item--mobile' : '',
          ]"
        >
          <div
            :class="[
              'vms-call-sheet-list__item--number vd-text-align-right vd-padding-small',
              isMobileScreen ? 'vd-dark-grey' : 'vd-margin-top-large',
            ]"
          >
            {{ index + 1 }}
          </div>
          <div
            :class="[
              'vms-call-sheet-list__item--handle vd-padding-small vd-text-align-center',
              isMobileScreen ? '' : 'vd-margin-top-large',
            ]"
          >
            <span v-if="index || isInitialScheduleEmpty" class="handle">
              &#9783;
            </span>
          </div>

          <template v-if="index !== onEditIndex">
            <div class="vms-call-sheet-list__item--time vd-padding-small">
              <div
                class="vms-call-sheet-list__item--label vd-dark-grey vd-margin-bottom-extra-small small"
              >
                Start time
              </div>
              {{ item.start_time | formatTime('HH:mm', 'hh:mm a') }}
            </div>
            <div class="vms-call-sheet-list__item--task vd-padding-small">
              <div
                class="vms-call-sheet-list__item--label vd-dark-grey vd-margin-bottom-extra-small small"
              >
                Task
              </div>
              {{ item.task || '-' }}
            </div>
            <div class="vms-call-sheet-list__item--notes vd-padding-small">
              <div
                class="vms-call-sheet-list__item--label vd-dark-grey vd-margin-bottom-extra-small small"
              >
                Notes
              </div>
              {{ item.notes || '-' }}
            </div>
          </template>

          <template v-else>
            <div
              class="vms-call-sheet-list__item--input-wrapper vms-call-sheet-list__item--time vd-padding-left-small vd-padding-right-small vd-padding-top-small"
            >
              <div
                class="vms-call-sheet-list__item--label vd-dark-grey vd-position-relative vd-red-dot small"
              >
                Start time
              </div>
              <input
                v-model="inputModel.start_time"
                type="time"
                class="vms-call-sheet-list__item--input vd-border-light-grey vd-border-width-1 vd-border-radius-5 vd-display-block vd-background-white vd-padding-extra-small"
              />
            </div>
            <div
              class="vms-call-sheet-list__item--input-wrapper vms-call-sheet-list__item--task vd-padding-left-small vd-padding-right-small vd-padding-top-small"
            >
              <div
                class="vms-call-sheet-list__item--label vd-dark-grey vd-position-relative vd-red-dot small"
              >
                Task
              </div>
              <input
                v-model="inputModel.task"
                type="text"
                class="vms-call-sheet-list__item--input vd-border-light-grey vd-border-width-1 vd-border-radius-5 vd-display-block vd-background-white vd-padding-extra-small"
              />
            </div>
            <div
              class="vms-call-sheet-list__item--input-wrapper vms-call-sheet-list__item--notes vd-padding-left-small vd-padding-right-small vd-padding-top-small"
            >
              <div class="vd-dark-grey small">Notes</div>
              <input
                v-model="inputModel.notes"
                type="text"
                class="vms-call-sheet-list__item--input vd-border-light-grey vd-border-width-1 vd-border-radius-5 vd-display-block vd-background-white vd-padding-extra-small"
              />
            </div>
          </template>

          <div
            v-if="index || isInitialScheduleEmpty"
            class="vms-call-sheet-list__item--action-wrapper vd-margin-top-large vd-padding-extra-small"
          >
            <template v-if="index !== onEditIndex">
              <span
                class="vms-call-sheet-list__item--action vd-icon-pencil-circle vd-margin-right-small"
                @click="handleItemEdit(index, item)"
              ></span>
              <span
                class="vms-call-sheet-list__item--action vd-icon-bin"
                @click="handleRemove(item)"
              ></span>
            </template>

            <template v-else>
              <span
                v-if="isFormValid"
                class="vms-call-sheet-list__item--action vd-icon-tick-circle vd-margin-right-small"
                @click="handleConfirmEdit(item)"
              ></span>
              <span
                class="vms-call-sheet-list__item--action vd-icon-close-circle"
                @click="resetForm"
              ></span>
            </template>
          </div>
        </div>
      </draggable>

      <div
        v-if="showNewItemForm"
        :class="[
          'vms-call-sheet-list__item',
          isMobileScreen ? 'vms-call-sheet-list__item--mobile' : '',
        ]"
      >
        <!-- filler column-->
        <template v-if="!isMobileScreen">
          <div
            class="vms-call-sheet-list__item--number vd-margin-top-large vd-padding-left-small vd-padding-right-small vd-padding-top-small vd-text-align-right"
          >
            -
          </div>
          <div
            class="vms-call-sheet-list__item--handle vd-margin-top-large vd-padding-left-small vd-padding-right-small vd-padding-top-small vd-text-align-center"
          >
            -
          </div>
        </template>

        <!-- form -->
        <div
          class="vms-call-sheet-list__item--input-wrapper vms-call-sheet-list__item--time vd-padding-left-small vd-padding-right-small vd-padding-top-small"
        >
          <div
            class="vms-call-sheet-list__item--label vd-dark-grey vd-position-relative vd-red-dot small"
          >
            Start time
          </div>
          <input
            v-model="inputModel.start_time"
            type="time"
            class="vms-call-sheet-list__item--input vd-border-light-grey vd-border-width-1 vd-border-radius-5 vd-display-block vd-background-white vd-padding-extra-small"
          />
        </div>
        <div
          class="vms-call-sheet-list__item--input-wrapper vms-call-sheet-list__item--task vd-padding-left-small vd-padding-right-small vd-padding-top-small"
        >
          <div
            class="vms-call-sheet-list__item--label vd-dark-grey vd-position-relative vd-red-dot small"
          >
            Task
          </div>
          <input
            v-model="inputModel.task"
            type="text"
            class="vms-call-sheet-list__item--input vd-border-light-grey vd-border-width-1 vd-border-radius-5 vd-display-block vd-background-white vd-padding-extra-small"
          />
        </div>
        <div
          class="vms-call-sheet-list__item--input-wrapper vms-call-sheet-list__item--notes vd-padding-left-small vd-padding-right-small vd-padding-top-small"
        >
          <div class="vd-dark-grey small">Notes</div>
          <input
            v-model="inputModel.notes"
            type="text"
            class="vms-call-sheet-list__item--input vd-border-light-grey vd-border-width-1 vd-border-radius-5 vd-display-block vd-background-white vd-padding-extra-small"
          />
        </div>

        <div
          class="vms-call-sheet-list__item--action-wrapper vd-margin-top-large vd-padding-extra-small"
        >
          <span
            v-if="isFormValid"
            class="vms-call-sheet-list__item--action vd-icon-tick-circle vd-margin-right-small"
            @click="handleConfirm"
          ></span>
          <span
            class="vms-call-sheet-list__item--action vd-icon-close-circle"
            @click="resetForm"
          ></span>
        </div>
      </div>
    </div>

    <div
      v-if="!showNewItemForm"
      class="vms-call-sheet-list__footer vd-text-align-right vd-padding-small"
    >
      <button class="vd-light-blue" @click="addNewItem">+ Add new</button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { isEmpty, assign } from 'lodash'
import { required } from 'vuelidate/lib/validators'
import FormMixin from '@mixins/forms-mixin'
import FiltersMixin from '@mixins/filters-mixin'
import draggable from 'vuedraggable'

export default {
  components: {
    draggable,
  },

  mixins: [FormMixin, FiltersMixin],

  props: {
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  validations: {
    inputModel: {
      start_time: {
        required,
      },
      task: {
        required,
      },
    },
  },

  data() {
    return {
      showNewItemForm: false,
      onEditIndex: undefined,
      inputModel: {
        start_time: '',
        task: '',
        notes: '',
      },
    }
  },

  computed: {
    ...mapGetters({
      callSheetData: 'project/shoot/callSheetData',
      isMobileScreen: 'common/isMobileScreen',
    }),

    isEmptyList() {
      return isEmpty(this.value)
    },

    isFormValid() {
      return !this.$v.$invalid
    },

    isInitialScheduleEmpty() {
      return isEmpty(this.callSheetData.shoot_schedule)
    },
  },

  methods: {
    // Hides the ghost image when dragging.
    modifyDragItem(dataTransfer) {
      const img = new Image()
      img.style.display = 'none'
      dataTransfer.setDragImage(img, 0, 0)
    },

    // Prevent 1st item to be move when initialSchedule is not empty.
    handleMove(event) {
      return event.relatedContext.index > 0 || this.isInitialScheduleEmpty
    },

    handleListChange(newList) {
      this.$emit('input', newList)
    },

    handleItemEdit(index, item) {
      this.resetForm()
      this.inputModel = assign(this.inputModel, item)
      this.onEditIndex = index
    },

    handleConfirm() {
      const newList = [...this.value, this.inputModel]
      this.$emit('input', newList)
      this.resetForm()
    },

    handleConfirmEdit(item) {
      const newList = this.value.map((sched) => {
        if (sched === item) {
          sched = assign({}, this.inputModel)
        }
        return sched
      })
      this.$emit('input', newList)
      this.resetForm()
    },

    handleRemove(item) {
      const newList = this.value.filter((i) => i !== item)
      this.$emit('input', newList)
    },

    resetForm() {
      this.inputModel = {
        start_time: '',
        task: '',
        notes: '',
      }
      this.showNewItemForm = false
      this.onEditIndex = undefined
    },

    addNewItem() {
      this.resetForm()
      this.showNewItemForm = true
    },
  },
}
</script>
