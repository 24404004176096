var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:['vd-field', _vm.containerCustomClasses]},[_c('label',[_vm._v(" "+_vm._s(_vm.productElementsSelectLabel)+" ")]),(_vm.productElementsList && _vm.canUpdateExtras)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.additionalExtras),expression:"additionalExtras"}],staticClass:"vd-margin-bottom-small",attrs:{"id":_vm.id,"required":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.additionalExtras=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[(_vm.availableExtras.length === 0)?_c('option',{attrs:{"disabled":"","selected":""},domProps:{"value":null}},[_vm._v(" No additional extras ")]):_c('option',{attrs:{"disabled":"","selected":""},domProps:{"value":null}},[_vm._v(" "+_vm._s(_vm.emptyOptionLabel)+" ")]),_vm._l((_vm.availableExtras),function(availableExtra,index){return _c('option',{key:index,attrs:{"disabled":_vm.isExtrasOptionDisabled(availableExtra.id) ||
          _vm.isExtrasInList(availableExtra.product_element_type.name)},domProps:{"value":availableExtra.id}},[_vm._v(" "+_vm._s(availableExtra.product_element_type.name)+" ")])})],2):_vm._e()]),(_vm.hasExtras && _vm.isEditType)?_c('div',{staticClass:"vms-product-elements--container"},_vm._l((_vm.selectedProductElements),function(productElementType,index){return _c('div',{key:index,class:[
        'vd-margin-extra-small vd-margin-left-0 vd-border-radius-50 vd-padding-block-extra-small vms-product-elements--selected',
        _vm.isExtraHovered(productElementType)
          ? 'vd-background-light-grey vd-padding-left-medium vd-padding-right-small'
          : 'vd-background-lighter-grey vd-padding-inline-large' ],on:{"click":function($event){return _vm.removeSelectedExtra(productElementType)},"mouseover":function($event){return _vm.handleExtraHover(productElementType)},"mouseleave":function($event){return _vm.handleExtraHover(null)}}},[_c('span',[_vm._v(" "+_vm._s(productElementType.name)+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExtraHovered(productElementType)),expression:"isExtraHovered(productElementType)"}],staticClass:"vd-icon-close-circle vms-product-elements--remove vd-margin-left-small"})])}),0):_vm._e(),(_vm.selectedExtras.length)?_c('div',{staticClass:"vms-product-elements--container"},_vm._l((_vm.selectedExtras),function(selectedExtra,index){return _c('div',{key:index,class:[
        'vd-margin-extra-small vd-margin-left-0 vd-border-radius-50 vd-padding-block-extra-small vms-product-elements--selected',
        _vm.isExtraHovered(selectedExtra)
          ? 'vd-background-light-grey vd-padding-left-medium vd-padding-right-small'
          : 'vd-background-lighter-grey vd-padding-inline-large' ],on:{"click":function($event){return _vm.removeSelectedExtra(selectedExtra)},"mouseover":function($event){return _vm.handleExtraHover(selectedExtra)},"mouseleave":function($event){return _vm.handleExtraHover(null)}}},[_c('span',[_vm._v(" "+_vm._s(_vm.getSelectedExtraName(selectedExtra))+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExtraHovered(selectedExtra)),expression:"isExtraHovered(selectedExtra)"}],staticClass:"vd-icon-close-circle vms-product-elements--remove vd-margin-left-small"})])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }