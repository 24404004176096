<template>
  <div
    :class="[
      'vms-call-sheet-form',
      isMobileScreen
        ? 'vms-call-sheet-form__mobile vd-background-white vd-margin-0'
        : 'vd-background-lightest-grey',
    ]"
  >
    <div
      :class="[
        'vms-call-sheet-form__header vd-padding-medium',
        isMobileScreen
          ? 'vms-call-sheet-form__header--mobile vd-background-white vd-border-light-grey vd-h3'
          : 'vd-padding-bottom-0',
      ]"
    >
      <span
        class="vd-icon-preprod-call vd-margin-right-small vms-call-sheet-form__icon"
      ></span>

      <span class="vd-h4">Call sheet</span>

      <div
        v-if="isMobileScreen"
        class="vms-call-sheet-form__close"
        @click="cancelForm"
      >
        <span class="vd-icon-close vms-call-sheet-form__close--icon"></span>
      </div>
    </div>

    <div
      v-if="showLoading"
      class="vd-padding-large vd-margin-top-large vd-text-align-center"
    >
      <default-loader></default-loader>
    </div>

    <template v-else>
      <div
        :class="[
          'vms-call-sheet-form__content',
          isSmallScreen
            ? 'vms-call-sheet-form__content--small vd-padding-medium'
            : 'vd-padding-large',
        ]"
      >
        <template v-if="!hasCustomCallSheet">
          <filming-call-sheet-events></filming-call-sheet-events>

          <filming-call-sheet-schedule
            v-model="formModel.shoot_schedule"
          ></filming-call-sheet-schedule>

          <div>
            <filming-call-sheet-project-contacts></filming-call-sheet-project-contacts>

            <filming-call-sheet-site-contact
              v-model="formModel.site_contact"
            ></filming-call-sheet-site-contact>

            <filming-call-sheet-other-contacts
              v-model="formModel.additional_contacts"
            ></filming-call-sheet-other-contacts>
          </div>

          <filming-call-sheet-remarks
            :value="formModel"
            @input:is-parking-required="formModel.is_parking_required = $event"
            @input:parking-description="formModel.parking_description = $event"
            @input:external-note="formModel.external_note = $event"
          ></filming-call-sheet-remarks>
        </template>
        <div v-else>
          <p class="vd-margin-bottom-0">
            Automatic call sheet has been stopped and custom link has been
            provided.
          </p>
          <a :href="callSheetUrl" target="_blank">{{ callSheetUrl }}</a>
        </div>
      </div>
      <div
        :class="[
          'vms-call-sheet-form__actions vd-padding-medium vd-padding-left-large vd-padding-right-large',
          isMobileScreen
            ? 'vms-call-sheet-form__actions--mobile vd-background-lightest-grey vd-border-light-grey'
            : 'vd-border-white',
        ]"
      >
        <button
          v-if="!callSheetLoading"
          class="vd-btn-small vd-padding-small"
          @click="cancelForm"
        >
          Cancel
        </button>

        <button
          class="vd-background-white vd-btn-small vd-margin-right-small"
          @click="toggleManualCallSheetModal"
        >
          <span
            class="vms-call-sheet-form__icon vd-icon-stop vd-margin-top-extra-small"
          ></span>
        </button>

        <a
          class="vd-background-white vd-btn-small vd-margin-right-small"
          :href="callSheetUrl"
          target="_blank"
        >
          <span
            class="vms-call-sheet-form__icon vd-icon-eye vd-margin-top-extra-small"
          ></span>
        </a>

        <button
          class="vd-btn-blue vd-btn-small vd-padding-small"
          :disabled="disableSave"
          @click="saveForm"
        >
          {{ callSheetLoading ? 'Loading...' : 'Save' }}
        </button>
      </div>
    </template>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { assign, isEqual, isEmpty } from 'lodash'
import SweetAlert from '@plugins/sweet-alert'
import DefaultLoader from '@components/loaders/default-loader'
import FilmingCallSheetEvents from './components/filming-call-sheet-events'
import FilmingCallSheetSchedule from './components/filming-call-sheet-schedule'
import FilmingCallSheetProjectContacts from './components/filming-call-sheet-project-contacts'
import FilmingCallSheetSiteContact from './components/filming-call-sheet-site-contact'
import FilmingCallSheetOtherContacts from './components/filming-call-sheet-other-contacts'
import FilmingCallSheetRemarks from './components/filming-call-sheet-remarks'
import AppConfig from '@configs/app-config'

export default {
  components: {
    DefaultLoader,
    FilmingCallSheetEvents,
    FilmingCallSheetSchedule,
    FilmingCallSheetProjectContacts,
    FilmingCallSheetSiteContact,
    FilmingCallSheetOtherContacts,
    FilmingCallSheetRemarks,
  },

  data() {
    return {
      showCallSheetModal: false,
      callSheetModalConfig: null,
      callSheetModalList: [],
      formModel: {},
    }
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      selectedFilmingDetails: 'project/shoot/selectedFilmingDetails',
      callSheetData: 'project/shoot/callSheetData',
      callSheetLoading: 'project/shoot/callSheetLoading',
      isMobileScreen: 'common/isMobileScreen',
      windowWidth: 'common/windowWidth',
      isEventFormLoading: 'calendar/isEventFormLoading',
    }),

    isSmallScreen() {
      return this.windowWidth < 1400
    },

    showLoading() {
      return (
        this.callSheetLoading &&
        this.callSheetData.order_job_id !==
          this.selectedFilmingDetails.order_job_id
      )
    },

    disableSave() {
      return (
        this.callSheetLoading ||
        isEqual(this.formModel, this.callSheetData) ||
        (!!this.formModel.is_parking_required &&
          !this.formModel.parking_description)
      )
    },

    hasCustomCallSheet() {
      return (
        this.selectedFilmingDetails &&
        !isEmpty(this.selectedFilmingDetails.manual_call_sheet_url)
      )
    },

    callSheetUrl() {
      return this.hasCustomCallSheet
        ? this.selectedFilmingDetails.manual_call_sheet_url
        : `${AppConfig.vmsApiUrl}/projects/${this.project.id}/jobs/shoots/${this.selectedFilmingDetails.id}/call-sheet`
    },
  },

  watch: {
    callSheetData(newVal) {
      this.formModel = assign({}, newVal)
    },

    isEventFormLoading(loading) {
      if (!loading) {
        this.getCallSheet({
          projectId: this.project.id,
          orderJobId: this.selectedFilmingDetails.order_job_id,
        })
      }
    },
  },

  mounted() {
    this.getCallSheet({
      projectId: this.project.id,
      orderJobId: this.selectedFilmingDetails.order_job_id,
    })
  },

  methods: {
    ...mapActions({
      updateSelectedFilmingDetails:
        'project/shoot/updateSelectedFilmingDetails',
      getCallSheet: 'project/shoot/getCallSheet',
      updateCallSheet: 'project/shoot/updateCallSheet',
      updateCallSheetFormOpen: 'project/shoot/updateCallSheetFormOpen',
      toggleManualCallSheetModal: 'project/shoot/toggleManualCallSheetModal',
    }),

    cancelForm() {
      SweetAlert.fire({
        title: 'Warning!',
        html: 'Are you sure you want to cancel your changes?',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        heightAuto: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.updateSelectedFilmingDetails(null)
          this.updateCallSheetFormOpen(false)
        }
      })
    },

    saveForm() {
      this.updateCallSheet({
        projectId: this.project.id,
        orderJobId: this.selectedFilmingDetails.order_job_id,
        orderJobName: this.selectedFilmingDetails.name,
        includes: this.formModel,
      })
    },
  },
}
</script>
