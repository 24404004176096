<template>
  <div
    :class="[
      !isMobileScreen ? 'vms-content__container' : '',
      isMediumScreen
        ? 'vms-filming-details__container--small'
        : 'vms-filming-details__container',
    ]"
  >
    <div class="vms-filming-details__container--content">
      <create-filming-details-form
        v-if="filmingDetailsNewFormOpen"
        action-type="create"
      ></create-filming-details-form>

      <default-loader
        v-if="filmingDetailsLoading"
        class="vd-margin-top-extra-large"
      ></default-loader>

      <div v-else>
        <empty-filming-details
          v-if="!hasShoots"
          image-src="/images/empty-states/empty-filming.svg"
          description="There are no filming details added to this project yet."
        ></empty-filming-details>

        <div v-else class="vd-padding-left-medium">
          <filming-details-list
            v-for="(filmingDetail, index) in filmingDetails.data"
            :key="index"
            :filming-detail="filmingDetail"
          ></filming-details-list>
        </div>
      </div>
    </div>
    <manual-call-sheet-modal v-if="manualCallSheetModalOpen" />
    <events-filming-details-edit-modal v-if="showEventsModal" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DefaultLoader from '@components/loaders/default-loader'
import EmptyFilmingDetails from '@components/empty-states/project/project-tab-empty-state'
import FilmingDetailsList from '@views/project/filming-details/project-filming-details-list'
import ManualCallSheetModal from '@views/project/filming-details/components/manual-call-sheet-modal'
import CreateFilmingDetailsForm from '@views/project/components/forms/filming-details/filming-details-form'
import SweetAlert from '@plugins/sweet-alert'
import EventsFilmingDetailsEditModal from '@components/modal/events/filming-details/events-filming-details-edit-modal'
export default {
  components: {
    DefaultLoader,
    EmptyFilmingDetails,
    FilmingDetailsList,
    CreateFilmingDetailsForm,
    ManualCallSheetModal,
    EventsFilmingDetailsEditModal,
  },

  provide: {
    formActionType: 'create',
    eventsListIncludes: 'projectEventsList',
  },

  computed: {
    ...mapGetters({
      selectedFilmingDetails: 'project/shoot/selectedFilmingDetails',
      filmingDetailsNewFormOpen: 'project/shoot/filmingDetailsNewFormOpen',
      filmingDetailsEditFormOpen: 'project/shoot/filmingDetailsEditFormOpen',
      filmingDetails: 'project/shoot/filmingDetails',
      filmingDetailsLoading: 'project/shoot/filmingDetailsLoading',
      hasProjectProductType: 'project/hasProjectProductType',
      projectDetails: 'project/projectDetails',
      callSheetFormOpen: 'project/shoot/callSheetFormOpen',
      manualCallSheetModalOpen: 'project/shoot/manualCallSheetModalOpen',
      isMobileScreen: 'common/isMobileScreen',
      isMediumScreen: 'common/isMediumScreen',
      showEventsModal: 'calendar/showEventsModal',
    }),

    hasShoots() {
      return this.filmingDetails.meta.total > 0
    },
  },

  beforeRouteLeave(to, from, next) {
    const vm = this

    if (
      vm.filmingDetailsNewFormOpen ||
      vm.filmingDetailsEditFormOpen ||
      vm.callSheetFormOpen
    ) {
      SweetAlert.fire({
        title: 'Confirm Navigation',
        html: `<br> You have unsaved changes. <br><br> Are you sure you want to leave this page?`,
        showCancelButton: true,
        confirmButtonText: 'Leave this page',
      }).then((result) => {
        if (result.isConfirmed) {
          vm.showAddNewFilmingDetailsForm(false)
          vm.showEditFilmingDetailsForm(false)
          vm.updateSelectedFilmingDetails(null)
          this.updateCallSheetFormOpen(false)
          next()
        }
      })
    } else {
      next()
    }
  },

  mounted() {
    if (this.hasProjectProductType) {
      this.updatePreProductionTypesList({
        projectId: this.projectDetails.id,
        include: ['product_element_type'],
      })
    }
  },

  methods: {
    ...mapActions({
      updateSelectedFilmingDetails:
        'project/shoot/updateSelectedFilmingDetails',
      showAddNewFilmingDetailsForm:
        'project/shoot/showAddNewFilmingDetailsForm',
      showEditFilmingDetailsForm: 'project/shoot/showEditFilmingDetailsForm',
      updatePreProductionTypesList:
        'project/pre-production/updatePreProductionTypesList',
      updateCallSheetFormOpen: 'project/shoot/updateCallSheetFormOpen',
    }),
  },
}
</script>

<style lang="scss">
@import '@styles/views/project/filming-details';
@import '@styles/views/project/call-sheet-form';
</style>
