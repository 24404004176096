<template>
  <div
    :class="[
      'vms-call-sheet-list__item vd-border-light-grey vd-position-relative',
      isMobileScreen ? 'vms-call-sheet-list__item--mobile' : '',
    ]"
  >
    <template v-if="!onEditMode">
      <div
        class="vms-call-sheet-list__item--user-profile vd-margin-top-extra-small vd-padding-small"
      >
        <user-profile
          :user="{
            full_name: value.full_name || value.name || ' ',
            photo_url: value.photo_url,
          }"
          custom-image-class="vms-call-sheet-list__item--avatar"
          custom-avatar-class="vms-call-sheet-list__item--avatar"
          :custom-avatar-font-size="40"
        ></user-profile>
      </div>
      <div class="vms-call-sheet-list__item--name vd-padding-small">
        <div
          class="vms-call-sheet-list__item--label vd-dark-grey vd-margin-bottom-extra-small small"
        >
          {{ value.role || defaultRole || '-' }}
        </div>
        <div>
          {{ value.full_name || value.name || '-' }}
        </div>
      </div>
      <div class="vms-call-sheet-list__item--phone vd-padding-small">
        <div
          class="vms-call-sheet-list__item--label vd-dark-grey vd-margin-bottom-extra-small small"
        >
          Phone
        </div>
        <div>
          {{ value.phone || '-' }}
        </div>
      </div>
      <div class="vms-call-sheet-list__item--email vd-padding-small">
        <div
          class="vms-call-sheet-list__item--label vd-dark-grey vd-margin-bottom-extra-small small"
        >
          Email
        </div>
        <div>
          {{ value.email || '-' }}
        </div>
      </div>
    </template>

    <template v-else>
      <div
        class="vms-call-sheet-list__item--input-wrapper vms-call-sheet-list__item--role-contact vd-padding-left-small vd-padding-right-small vd-padding-top-small"
      >
        <div class="vms-call-sheet-list__item--label vd-dark-grey small">
          Role
        </div>
        <div
          v-if="defaultRole"
          class="vms-call-sheet-list__item--input vd-display-block vd-padding-top-extra-small vd-padding-bottom-extra-small"
        >
          {{ defaultRole }}
        </div>
        <input
          v-else
          v-model="inputModel.role"
          type="text"
          class="vms-call-sheet-list__item--input vd-border-light-grey vd-border-width-1 vd-border-radius-5 vd-display-block vd-background-white vd-padding-extra-small"
        />
      </div>
      <div
        class="vms-call-sheet-list__item--input-wrapper vms-call-sheet-list__item--name vd-padding-left-small vd-padding-right-small vd-padding-top-small"
      >
        <div class="vms-call-sheet-list__item--label vd-dark-grey small">
          Name
        </div>
        <input
          v-model="inputModel.name"
          type="text"
          class="vms-call-sheet-list__item--input vd-border-light-grey vd-border-width-1 vd-border-radius-5 vd-display-block vd-background-white vd-padding-extra-small"
        />
      </div>
      <div
        class="vms-call-sheet-list__item--input-wrapper vms-call-sheet-list__item--phone vd-padding-left-small vd-padding-right-small vd-padding-top-small"
      >
        <div class="vms-call-sheet-list__item--label vd-dark-grey small">
          Phone
        </div>
        <input
          v-model="inputModel.phone"
          type="text"
          class="vms-call-sheet-list__item--input vd-border-light-grey vd-border-width-1 vd-border-radius-5 vd-display-block vd-background-white vd-padding-extra-small"
        />
      </div>
      <div
        class="vms-call-sheet-list__item--input-wrapper vms-call-sheet-list__item--email vd-padding-left-small vd-padding-right-small vd-padding-top-small"
      >
        <div class="vms-call-sheet-list__item--label vd-dark-grey small">
          Email
        </div>
        <input
          v-model="inputModel.email"
          type="email"
          class="vms-call-sheet-list__item--input vd-border-light-grey vd-border-width-1 vd-border-radius-5 vd-display-block vd-background-white vd-padding-extra-small"
        />
      </div>
    </template>

    <div
      v-if="!isReadOnly"
      class="vms-call-sheet-list__item--action-wrapper vd-margin-top-large vd-padding-left-small vd-padding-right-small vd-padding-top-small"
    >
      <template v-if="!onEditMode">
        <span
          class="vms-call-sheet-list__item--action vd-icon-pencil-circle vd-margin-right-small"
          @click="handleItemEdit"
        ></span>

        <span
          class="vms-call-sheet-list__item--action vd-icon-bin"
          @click="handleRemove"
        ></span>
      </template>

      <template v-else>
        <span
          v-if="isFormValid"
          class="vms-call-sheet-list__item--action vd-icon-tick-circle vd-margin-right-small"
          @click="handleConfirmEdit"
        ></span>
        <span
          class="vms-call-sheet-list__item--action vd-icon-close-circle"
          @click="resetForm"
        ></span>
      </template>
    </div>
  </div>
</template>

<script>
import { assign } from 'lodash'
import { mapGetters } from 'vuex'
import UserProfile from '@components/user-profile/user-profile'
import FormMixin from '@mixins/forms-mixin'
import { email } from 'vuelidate/lib/validators'

export default {
  components: {
    UserProfile,
  },

  mixins: [FormMixin],

  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    isReadOnly: {
      type: Boolean,
      required: false,
      default: false,
    },

    defaultRole: {
      type: String,
      required: false,
      default: '',
    },
  },

  validations: {
    inputModel: {
      email: {
        email,
      },
    },
  },

  data() {
    return {
      onEditMode: false,
      inputModel: {
        name: '',
        phone: '',
        email: '',
        photo_url: '',
        role: this.defaultRole,
      },
    }
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
    }),

    isFormValid() {
      return (
        !this.$v.$invalid &&
        (this.inputModel.name || this.inputModel.phone || this.inputModel.email)
      )
    },
  },

  methods: {
    handleItemEdit() {
      this.resetForm()
      this.inputModel = assign({}, this.inputModel, this.value)
      this.onEditMode = true
    },

    handleConfirmEdit() {
      if (!this.isFormValid) {
        return
      }
      const contact = assign({}, this.inputModel)
      this.$emit('click:update', contact)
      this.resetForm()
    },

    handleRemove() {
      this.$emit('click:remove', this.value)
    },

    resetForm() {
      this.inputModel = {
        name: '',
        phone: '',
        email: '',
        photo_url: '',
        role: this.defaultRole,
      }

      this.onEditMode = false
    },
  },
}
</script>
