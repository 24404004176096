<template>
  <default-modal
    :class="['show']"
    modal-dialog-class="modal-lg"
    content-class="vd-background-white "
  >
    <div slot="modal-header" class="modal-header">
      <div class="vd-h4 vd-light-blue">
        Stop automatic Call Sheet &amp; replace with manual Call Sheet
      </div>
      <div class="vms-modal__header--close close vd-black">
        <span v-show="!isLoading" @click="handleCancel">x</span>
      </div>
    </div>
    <div slot="modal-body" class="modal-body">
      <div class="row">
        <div class="col-12">
          <div v-if="isLoading" class="vms-form-modal__loader">
            <default-loader></default-loader>
          </div>

          <div v-else class="vd-margin-bottom-10">
            <input-component
              type="text"
              name="manualCallSheetLink"
              label="Paste link to Call Sheet"
              :required="true"
              :value="manualCallSheetLink"
              :container-custom-classes="[
                setInputValidationClass('manualCallSheetLink'),
              ]"
              @formInput="handleInputUpdate($event, 'manualCallSheetLink')"
            ></input-component>
            <div
              v-show="showWarningMessage"
              class="vd-h5 vd-margin-top-small vd-red"
            >
              Warning: Make sure the document linked here has valid permissions
              for everyone to access it.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-show="!isLoading"
      slot="modal-footer"
      class="modal-footer vd-text-align-right"
    >
      <button
        v-show="showClearButton"
        class="vd-btn-small vd-margin-right-small"
        @click="handleClear"
      >
        <span v-if="isMobileScreen">Revert</span>
        <span v-else>Revert to the automatic call sheet</span>
      </button>
      <button
        class="vd-btn-small vd-btn-blue"
        :disabled="isSaveButtonDisabled"
        @click="handleSave"
      >
        Save
      </button>
    </div>
  </default-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { isEmpty } from 'lodash'
import DefaultModal from '@components/modal/default-modal'
import DefaultLoader from '@components/loaders/default-loader'
import InputComponent from '@components/form/form-input'
import SweetAlert from '@plugins/sweet-alert'
import ApiCustomIncludes from '@configs/api-custom-includes'
import FormMixin from '@mixins/forms-mixin'
import { required, url } from 'vuelidate/lib/validators'

export default {
  components: {
    DefaultModal,
    DefaultLoader,
    InputComponent,
  },

  mixins: [FormMixin],

  data() {
    return {
      manualCallSheetLink: '',
    }
  },

  validations: {
    manualCallSheetLink: {
      required,
      url,
    },
  },

  computed: {
    ...mapGetters({
      isLoading: 'project/shoot/manualCallSheetModalLoading',
      isMobileScreen: 'common/isMobileScreen',
      selectedFilmingDetails: 'project/shoot/selectedFilmingDetails',
      project: 'project/projectDetails',
    }),

    hasChanges() {
      return (
        this.selectedFilmingDetails &&
        this.manualCallSheetLink !==
          this.selectedFilmingDetails.manual_call_sheet_url
      )
    },

    isFormInvalid() {
      return this.$v.$invalid
    },

    isSaveButtonDisabled() {
      return !this.hasChanges || this.isFormInvalid
    },

    showWarningMessage() {
      return !isEmpty(this.manualCallSheetLink)
    },

    showClearButton() {
      return (
        this.selectedFilmingDetails &&
        !isEmpty(this.selectedFilmingDetails.manual_call_sheet_url)
      )
    },
  },

  watch: {
    selectedFilmingDetails() {
      this.resetInputs()
    },
  },

  mounted() {
    this.resetInputs()
  },

  methods: {
    ...mapActions({
      toggleManualCallSheetModal: 'project/shoot/toggleManualCallSheetModal',
      updateManualCallSheet: 'project/shoot/updateManualCallSheet',
    }),

    resetInputs() {
      if (this.selectedFilmingDetails) {
        this.manualCallSheetLink = this.selectedFilmingDetails.manual_call_sheet_url
      }
    },

    handleClear() {
      SweetAlert.fire({
        title: 'Warning!',
        html: 'Are you sure you want to remove the custom url?',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
      }).then((result) => {
        if (result.isConfirmed) {
          this.manualCallSheetLink = ''

          if (this.hasChanges) {
            this.handleSave()
          } else {
            this.toggleManualCallSheetModal()
          }
        }
      })
    },

    handleSave() {
      this.updateManualCallSheet({
        project_id: this.project.id,
        shoot_job_id: this.selectedFilmingDetails.order_job_id,
        url: this.manualCallSheetLink ?? '',
        include: ApiCustomIncludes.filmingDetailsList,
      })
    },

    handleCancel() {
      if (!this.hasChanges) {
        this.toggleManualCallSheetModal()
        return
      }

      SweetAlert.fire({
        title: 'Warning!',
        html: 'Are you sure you want to cancel your changes?',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
      }).then((result) => {
        if (result.isConfirmed) {
          this.resetInputs()
          this.toggleManualCallSheetModal()
        }
      })
    },
  },
}
</script>
