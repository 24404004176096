<template>
  <div class="row no-gutters vd-align-center">
    <div class="col-lg-6">
      <input-component
        ref="street"
        :value="street"
        :required="isRequired"
        :disabled="disabled"
        :is-google-maps-type="true"
        :container-custom-classes="[
          'vd-field vd-padding-medium',
          street ? 'vd-input-valid' : '',
          isRequired ? 'vd-required' : '',
        ]"
        type="text"
        name="street"
        label="Street Address"
        placeholder="Enter an address to start the search"
        autocomplete="off"
        @formInput="updateAddressFields"
      ></input-component>
    </div>

    <div class="col-lg-6">
      <div
        :class="[
          !isMobileScreen ? 'vd-display-flex vd-flex-wrap vd-align-center' : '',
        ]"
      >
        <input-component
          v-if="hasSuburb"
          :required="isRequired"
          :disabled="disabled"
          :value="suburb"
          :container-custom-classes="[
            'vd-field col-md-4',
            suburb ? 'vd-input-valid' : '',
            isRequired ? 'vd-required' : '',
          ]"
          type="text"
          name="filmingDetailsSuburb"
          label="Suburb"
          placeholder="Enter shoot suburb"
          autocomplete="off"
          @formInput="handleSuburbInput"
        ></input-component>

        <div class="col-md-4">
          <v-select
            :value="state"
            :items="states"
            :disabled="disabled"
            :menu-props="menuProps"
            :class="[
              'vd-field',
              state ? 'vd-input-valid' : '',
              isRequired ? 'vd-required' : '',
            ]"
            placeholder="Select state"
            persistent-placeholder
            label="State"
            item-text="name"
            item-value="id"
            no-data-text="No results to display"
            clearable
            @change="handleStateInput"
          ></v-select>
        </div>

        <input-component
          v-if="hasPostcode"
          :disabled="disabled"
          :required="isRequired"
          :value="postcode"
          :container-custom-classes="[
            'vd-field col-md-4',
            postcode ? 'vd-input-valid' : '',
            isRequired ? 'vd-required' : '',
          ]"
          type="text"
          name="filmingDetailsPostcode"
          label="Postcode"
          placeholder="Enter postcode"
          autocomplete="off"
          @formInput="handlePostcodeInput"
        ></input-component>
      </div>
    </div>
  </div>
</template>

<script>
import InputComponent from '@components/form/form-input'
import StatesConstants from '@configs/states'
import { mapGetters } from 'vuex'
import { find, isObject, isEmpty } from 'lodash'
import Vuetify from 'vuetify'

export default {
  vuetify: new Vuetify(),

  components: {
    InputComponent,
  },

  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    street: {
      type: String,
      required: false,
      default: '',
    },

    suburb: {
      type: String,
      required: false,
      default: '',
    },

    state: {
      type: Number,
      required: false,
      default: null,
    },

    postcode: {
      type: String,
      required: false,
      default: '',
    },

    hasSuburb: {
      type: Boolean,
      required: false,
      default: false,
    },

    hasPostcode: {
      type: Boolean,
      required: false,
      default: false,
    },

    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      states: StatesConstants.states,
      menuProps: {
        closeOnClick: true,
        offsetY: true,
      },
    }
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
    }),
  },

  beforeDestroy() {
    // Cleanup. Manually remove the google maps script tag.
    document.querySelector('.pac-container')?.remove()
  },

  methods: {
    updateAddressFields(addressData) {
      if (isObject(addressData) && !isEmpty(addressData)) {
        const state = find(this.states, (state) => {
          return state.name === addressData.state
        }).id

        this.$emit('update:state', state)

        let address = addressData.street

        if (!this.hasSuburb) {
          address += `, ${addressData.suburb}`
        } else {
          this.$emit('update:suburb', addressData.suburb)
        }

        if (!this.hasPostcode) {
          address += `, ${addressData.postcode}`
        } else {
          this.$emit('update:postcode', addressData.postcode)
        }
        this.$emit('update:street', address)
        // Update the value of inputComponent without triggering the value watcher.
        // Updating the value through props will cause google map options to be created again.
        this.$refs.street.inputValue = address
      } else {
        this.$emit('update:street', addressData)
      }
    },

    handleSuburbInput(suburb) {
      this.$emit('update:suburb', suburb)
    },

    handleStateInput(state) {
      this.$emit('update:state', state)
    },

    handlePostcodeInput(postcode) {
      this.$emit('update:postcode', postcode)
    },
  },
}
</script>
