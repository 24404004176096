<template>
  <div class="vms-call-sheet-list__wrapper">
    <div class="vd-margin-bottom-small vd-h4">
      Shoot Events

      <text-popover
        class="vd-display-inline-block"
        tooltip-text="Assign and schedule these events via the calendar"
        tooltip-icon="vd-icon-tooltip vms-call-sheet-list__tooltip-icon vd-margin-left-small"
        tooltip-id="shootEventsTooltip"
        tooltip-trigger="click blur"
        :popover-custom-class="
          isMobileScreen ? 'vms-project__popover--small' : ''
        "
      >
      </text-popover>
    </div>

    <div
      v-if="isEmptyList"
      class="small vd-dark-grey vd-text-align-center vd-margin-top-large vd-margin-bottom-large"
    >
      No shoot events, please schedule or move off hold
    </div>

    <div class="vms-call-sheet-list vd-background-lightest-grey">
      <div
        v-for="(callSheetEvent, index) in callSheetData.event_users"
        :key="index"
        :class="[
          'vms-call-sheet-list__item vd-border-light-grey vd-position-relative',
          isMobileScreen ? 'vms-call-sheet-list__item--mobile' : '',
        ]"
      >
        <div
          class="vms-call-sheet-list__item--user-profile vd-margin-top-extra-small vd-padding-small"
        >
          <user-profile
            :user="getEventUser(callSheetEvent)"
            custom-image-class="vms-call-sheet-list__item--avatar"
            custom-avatar-class="vms-call-sheet-list__item--avatar"
            :custom-avatar-font-size="40"
          ></user-profile>
        </div>
        <div class="vms-call-sheet-list__item--name vd-padding-small">
          <div
            class="vms-call-sheet-list__item--label vd-dark-grey vd-margin-bottom-extra-small small"
          >
            Full Name
          </div>
          <div>
            <span v-if="callSheetEvent.name">
              {{ callSheetEvent.name }}
            </span>
            <span
              v-else-if="
                callSheetEvent.meta_data && callSheetEvent.meta_data.freelancer
              "
            >
              {{ callSheetEvent.meta_data.freelancer }}
            </span>
            <span v-else class="vd-red">Unassigned</span>
          </div>
        </div>
        <div class="vms-call-sheet-list__item--role vd-padding-small">
          <div
            class="vms-call-sheet-list__item--label vd-dark-grey vd-margin-bottom-extra-small small"
          >
            Role
          </div>
          <div>
            {{ callSheetEvent.role || '-' }}
          </div>
        </div>
        <div class="vms-call-sheet-list__item--date-time vd-padding-small">
          <div
            class="vms-call-sheet-list__item--label vd-dark-grey vd-margin-bottom-extra-small small"
          >
            Production Date / Time
          </div>
          <div>
            {{ callSheetEvent.from | unixToFormat('DD/MM/YY hh:mm a') }}
          </div>
        </div>
        <div
          class="vms-call-sheet-list__item--duration vd-padding-small vd-text-nowrap vd-display-flex"
        >
          <div class="vd-margin-right-small">
            <div
              class="vms-call-sheet-list__item--label vd-dark-grey vd-margin-bottom-extra-small small"
            >
              Duration
            </div>
            <div>
              {{ getDuration(callSheetEvent) }}
            </div>
          </div>
          <button
            class="vd-margin-left-auto vd-display-inline-block vd-margin-top-large"
            @click="handleViewCallSheetEvent(callSheetEvent)"
          >
            <text-popover
              tooltip-text="View/update production date/time"
              tooltip-icon="vd-icon icon-eye"
              custom-tooltip-class="vd-hover-light-blue"
              tooltip-placement="top"
              :tooltip-id="`viewEventTooltip${callSheetEvent.id}`"
            ></text-popover>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { isEmpty } from 'lodash'
import momentTz from 'moment-timezone'
import FiltersMixin from '@mixins/filters-mixin'
import UserProfile from '@components/user-profile/user-profile'
import TextPopover from '@components/tooltips/text-popover'
import ApiCustomIncludes from '@configs/api-custom-includes'
import Colours from '@configs/colours'

export default {
  components: {
    UserProfile,
    TextPopover,
  },

  mixins: [FiltersMixin],

  computed: {
    ...mapGetters({
      callSheetData: 'project/shoot/callSheetData',
      isMobileScreen: 'common/isMobileScreen',
    }),

    isEmptyList() {
      return isEmpty(this.callSheetData.event_users)
    },
  },

  methods: {
    ...mapActions({
      setSelectedEvent: 'calendar/setSelectedEvent',
      setShowEventsModal: 'calendar/setShowEventsModal',
      getEventDetails: 'calendar/getEventDetails',
    }),

    getDuration(callSheetEvent) {
      const duration = callSheetEvent.to - callSheetEvent.from
      if (isNaN(duration)) {
        return '0 hour'
      }
      const hour = momentTz.duration(duration, 'seconds').asHours()
      return `${hour} ${hour > 1 ? 'hours' : 'hour'}`
    },

    getEventUser(callSheetEvent) {
      if (callSheetEvent.name) {
        return {
          full_name: callSheetEvent.name || ' ',
          photo_url: callSheetEvent.photo_url,
          calendar_color: callSheetEvent.calendar_color,
        }
      } else if (callSheetEvent.meta_data?.freelancer) {
        return {
          full_name: callSheetEvent.meta_data.freelancer,
          calendar_color: Colours.eventColours.freelancer,
        }
      } else {
        return {
          full_name: '-',
          calendar_color: Colours.eventColours.unassigned,
        }
      }
    },

    handleViewCallSheetEvent(callSheetEvent) {
      const event = {
        id: callSheetEvent.id,
        event_type_id: callSheetEvent.event_type_id,
        from: callSheetEvent.from,
        to: callSheetEvent.to,
        meta_data: callSheetEvent.meta_data,
        name: callSheetEvent.event_name,
        user: callSheetEvent.name
          ? {
              full_name: callSheetEvent.name,
              photo_url: callSheetEvent.photo_url,
              calendar_color: callSheetEvent.calendar_color,
            }
          : null,
        event_type: {
          id: callSheetEvent.event_type_id,
          name: callSheetEvent.role,
        },
      }
      this.getEventDetails({
        eventId: event.id,
        include: ApiCustomIncludes.eventsDetails,
      })
      this.setSelectedEvent(event)
      this.setShowEventsModal(true)
    },
  },
}
</script>
