<template>
  <div
    class="vd-padding-small vd-darker-grey vd-border-radius-6"
    :style="eventColorStyle"
  >
    <div class="vd-text-small vd-text-strong">Location</div>
    <div
      class="vd-display-flex vd-flex-wrap vd-align-center vd-justify-between"
    >
      <div>
        <a
          v-if="location"
          :href="locationLink"
          target="_blank"
          class="vd-h5 vd-blue vd-hover-blue vd-border-blue vd-border-width-0 vd-border-bottom-width-1 vd-text-decoration-none"
          >{{ location }}</a
        >
        <span v-else>--</span>
      </div>
      <div v-if="showEditLocationIcon" class="vd-text-small">
        <span
          class="vd-icon vd-icon-circle vd-hover-background-light-blue vd-hover-white icon-pencil"
          @click="handleEditLocation"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['getEventColorStyle'],

  props: {
    location: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      showEditLocationIcon: true,
    }
  },

  computed: {
    locationLink() {
      return `https://maps.google.com/?q=${this.location}`
    },
    eventColorStyle() {
      return this.getEventColorStyle()
    },
  },

  methods: {
    handleEditLocation() {
      this.showEditLocationIcon = false
      this.$emit('click:edit-address')
    },
  },
}
</script>
