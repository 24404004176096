<template>
  <div class="vms-filming-details__header vms-filming-details__header--mobile">
    <div
      id="viewFormDetailsDiv"
      class="vd-position-relative vd-padding-right-extra-large"
      role="button"
      @click="$emit('click:view')"
    >
      <span class="vd-light-blue vd-margin-right-30">
        <strong>{{ filmingDetail.name }}</strong>
      </span>
      <span
        class="vms-filming-details__view vd-icon-arrow-right-grey-black vd-position-absolute"
      ></span>
    </div>

    <div class="vd-position-relative vd-margin-bottom-small">
      <small class="vd-dark-grey vd-margin-right-small">Date:</small>
      <template v-if="filmingDetail.production_date">
        <small class="vd-margin-right-extra-small"
          >{{ isOnHold ? '(TBC)' : '' }}
          {{
            filmingDetail.production_date | unixToFormat('dddd Do MMMM YYYY')
          }}
        </small>
        <small>{{
          filmingDetail.production_date | unixToFormat('hh:mm a')
        }}</small>
      </template>
      <template v-else>
        <small>TBC</small>
      </template>
    </div>

    <div
      class="vms-filming-details__header--button-wrapper vd-margin-bottom-small"
    >
      <div class="vms-filming-details__map--mobile">
        <google-map :location="filmingDetail.location"></google-map>
      </div>

      <assigned-crew
        class="vd-margin-left-large"
        :assigned-crew="filmingDetail.order_job_assignees"
        :max-user-display-limit="1"
      ></assigned-crew>
    </div>

    <div class="vms-filming-details__header--button-wrapper">
      <button
        class="vms-filming-details__header--button vms-filming-details__header--button__mobile vd-border-radius-10 vd-padding-left-small vd-padding-right-small vd-background-lightest-grey vd-dark-grey vd-border-lightest-grey vd-margin-right-small vd-padding-top-extra-small vd-padding-bottom-extra-small"
        @click="$emit('click:call-sheet')"
      >
        <span
          class="vms-filming-details__header--button__icon vms-filming-details__action-btn--mobile vd-icon icon-paper-phone vd-h5 vd-darker-grey"
        ></span>
        <span
          v-if="!isExtraSmallScreen"
          class="vd-display-inline-block vd-margin-left-small"
        >
          Call sheet
        </span>
      </button>

      <span
        v-if="isHoldShootJobLoading"
        class="vms-filming-details__header--button__mobile vd-padding-extra-small vd-dark-grey vd-text-align-center"
      >
        Loading...
      </span>

      <button
        v-else
        :class="[
          'vms-filming-details__header--button vms-filming-details__header--button__mobile vd-border-radius-10 vd-padding-left-small vd-padding-right-small vd-padding-right-small vd-padding-top-extra-small vd-margin-right-small vd-padding-bottom-extra-small',
          isOnHold
            ? 'vd-background-cream vd-yellow vd-border-yellow'
            : ' vd-background-lightest-grey vd-dark-grey vd-border-lightest-grey',
        ]"
        @click="$emit('click:hold')"
      >
        <span
          class="vms-filming-details__header--button__icon vd-icon-pause-circle"
        ></span>

        <span
          v-if="!isExtraSmallScreen"
          class="vd-display-inline-block vd-margin-left-small"
        >
          {{ isOnHold ? 'On Hold' : 'Hold' }}
        </span>

        <span
          v-if="isOnHold"
          class="vd-display-inline-block vd-margin-left-small vd-black"
          >&#10005;</span
        >
      </button>

      <button
        class="vms-filming-details__header--button vms-filming-details__header--button__mobile vd-border-radius-10 vd-padding-inline-small vd-background-lightest-grey vd-dark-grey vd-border-lightest-grey vd-padding-block-extra-small"
        @click="handleEodNotesClick"
      >
        <span
          class="vms-filming-details__header--button__icon vms-filming-details__action-btn--mobile vd-icon icon-chat vd-h5 vd-darker-grey"
        ></span>
      </button>
    </div>
  </div>
</template>

<script>
import AssignedCrew from '@components/assigned-crew/assigned-crew'
import GoogleMap from '@components/google-map/google-map'
import FiltersMixin from '@mixins/filters-mixin'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    AssignedCrew,
    GoogleMap,
  },

  mixins: [FiltersMixin],

  props: {
    filmingDetail: {
      type: Object,
      required: true,
      default: () => ({}),
    },

    isOnHold: {
      type: Boolean,
      required: false,
      default: false,
    },

    isHoldShootJobLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      windowWidth: 'common/windowWidth',
    }),

    isExtraSmallScreen() {
      return this.windowWidth < 426
    },
  },

  methods: {
    ...mapActions({
      setSelectedEodNoteTypeId: 'project/setSelectedEodNoteTypeId',
      toggleEodNotesModal: 'project/toggleEodNotesModal',
    }),

    handleEodNotesClick() {
      this.setSelectedEodNoteTypeId(this.filmingDetail.id)
      this.toggleEodNotesModal(true)
    },
  },
}
</script>
