var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row no-gutters vd-align-center"},[_c('div',{staticClass:"col-lg-6"},[_c('input-component',{ref:"street",attrs:{"value":_vm.street,"required":_vm.isRequired,"disabled":_vm.disabled,"is-google-maps-type":true,"container-custom-classes":[
        'vd-field vd-padding-medium',
        _vm.street ? 'vd-input-valid' : '',
        _vm.isRequired ? 'vd-required' : '' ],"type":"text","name":"street","label":"Street Address","placeholder":"Enter an address to start the search","autocomplete":"off"},on:{"formInput":_vm.updateAddressFields}})],1),_c('div',{staticClass:"col-lg-6"},[_c('div',{class:[
        !_vm.isMobileScreen ? 'vd-display-flex vd-flex-wrap vd-align-center' : '' ]},[(_vm.hasSuburb)?_c('input-component',{attrs:{"required":_vm.isRequired,"disabled":_vm.disabled,"value":_vm.suburb,"container-custom-classes":[
          'vd-field col-md-4',
          _vm.suburb ? 'vd-input-valid' : '',
          _vm.isRequired ? 'vd-required' : '' ],"type":"text","name":"filmingDetailsSuburb","label":"Suburb","placeholder":"Enter shoot suburb","autocomplete":"off"},on:{"formInput":_vm.handleSuburbInput}}):_vm._e(),_c('div',{staticClass:"col-md-4"},[_c('v-select',{class:[
            'vd-field',
            _vm.state ? 'vd-input-valid' : '',
            _vm.isRequired ? 'vd-required' : '' ],attrs:{"value":_vm.state,"items":_vm.states,"disabled":_vm.disabled,"menu-props":_vm.menuProps,"placeholder":"Select state","persistent-placeholder":"","label":"State","item-text":"name","item-value":"id","no-data-text":"No results to display","clearable":""},on:{"change":_vm.handleStateInput}})],1),(_vm.hasPostcode)?_c('input-component',{attrs:{"disabled":_vm.disabled,"required":_vm.isRequired,"value":_vm.postcode,"container-custom-classes":[
          'vd-field col-md-4',
          _vm.postcode ? 'vd-input-valid' : '',
          _vm.isRequired ? 'vd-required' : '' ],"type":"text","name":"filmingDetailsPostcode","label":"Postcode","placeholder":"Enter postcode","autocomplete":"off"},on:{"formInput":_vm.handlePostcodeInput}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }