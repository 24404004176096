<template>
  <div class="vms-link-pre-production">
    <div class="vms-link-pre-production__options vd-margin-right-extra-small">
      <div
        v-click-outside="closePreProductionList"
        class="vms-link-pre-production__dropdown vd-position-relative vd-margin-right-extra-small"
      >
        <div
          class="vms-link-pre-production__align--middle vms-link-pre-production__dropdown--label vd-padding-right-extra-small vd-margin-bottom-small"
        >
          <div
            class="vms-link-pre-production__dropdown--label__text vd-dark-grey vd-h5"
          >
            Pre-production
          </div>
          <div
            v-if="nonLinkedPreProductions.length > 0"
            class="vms-link-pre-production__dropdown--label__icon"
            @click="toggleShowPreProductionDropdownList"
          >
            <span
              class="vms-link-pre-production__icons--arrow vd-icon-arrow-down-grey-black"
            ></span>
          </div>
        </div>
        <div
          v-if="showPreProductionDropdownList"
          class="vms-dropdown vd-box-shadow vms-link-pre-production__dropdown--list vd-background-white vd-border-radius-6"
        >
          <div class="vms-link-pre-production__dropdown--list__wrapper">
            <div
              v-for="(preProduction, index) in nonLinkedPreProductions"
              :key="index"
              class="vms-link-pre-production__dropdown--list-options vd-padding-extra-small"
            >
              <input
                :id="`preProductionSelectCheckbox-${index}`"
                v-model="selectedPreProductions"
                type="checkbox"
                class="vd-margin-right-small"
                :value="preProduction.id"
              />
              <label
                :for="`preProductionSelectCheckbox-${index}`"
                class="vd-margin-0"
              >
                <text-popover
                  class="vd-display-inline-block"
                  :tooltip-text="preProduction.name"
                  :tooltip-label="getTruncatedLabel(preProduction.name, 20)"
                  :show-icon="false"
                  :tooltip-id="`preProductionSelectCheckBox${preProduction.id}Tooltip${linkingJobId}`"
                  tooltip-placement="right"
                ></text-popover>
              </label>
            </div>
          </div>
          <div
            class="vms-link-pre-production__dropdown--list__footer vd-border-grey"
          >
            <button
              class="vd-btn-small vd-btn-grey vd-margin-extra-small"
              @click="toggleShowPreProductionDropdownList"
            >
              Cancel
            </button>
            <button
              class="vd-btn-small vd-btn-blue vd-margin-extra-small"
              :disabled="isSubmitButtonDisabled"
              @click="handleSubmit"
            >
              Submit
            </button>
          </div>
        </div>
      </div>

      <div
        v-click-outside="closePreProductionTypeList"
        class="vms-link-pre-production__add"
      >
        <span
          class="vd-icon-plus-circle vms-link-pre-production__icons--add"
          @click="toggleShowPreProductionTypeDropdownList"
        >
        </span>
        <div
          v-if="showPreProductionTypeDropdownList"
          class="vms-dropdown vms-link-pre-production__dropdown--list vd-box-shadow vd-background-white vd-border-radius-6"
        >
          <div class="vms-link-pre-production__dropdown--list__wrapper">
            <div
              v-for="(type, index) in preProductionTypes"
              :id="`preProductionTypeListDiv-${index}`"
              :key="type.id"
              class="vms-link-pre-production__dropdown--list-options vd-black vd-padding-small"
              @click="showNewPreProductionForm(type)"
            >
              {{ type.product_element_type.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <span
      v-if="!hasLinkedPreProductions"
      class="vms-link-pre-production__align--middle vd-black vms-link-pre-production__selected--empty vd-h5"
      >Here you can select or add new Pre-production documents.</span
    >
    <div v-else class="vms-link-pre-production__selected vd-position-relative">
      <div
        v-for="(preProduction, index) in linkedPreProductions"
        :key="index"
        class="vd-margin-bottom-extra-small vd-margin-right-extra-small vd-margin-left-0 vms-link-pre-production__selected--items"
      >
        <div
          class="vd-border-radius-50 vd-background-lightest-grey vms-link-pre-production__align--middle"
        >
          <div
            :class="[
              'vms-link-pre-production__wrapper vd-margin-left-medium vd-margin-right-medium vms-link-pre-production__align--middle',
            ]"
          >
            <a
              :href="preProduction.file_url"
              target="_blank"
              rel="noopener"
              :title="preProduction.name"
              class="vd-a"
            >
              <text-popover
                class="vd-h5 vd-display-inline-block vd-padding-top-small vd-padding-bottom-small vd-padding-left-extra-small vd-padding-right-extra-small"
                :tooltip-text="preProduction.name"
                :tooltip-label="getTruncatedLabel(preProduction.name, 20)"
                :show-icon="false"
                :tooltip-id="`linkedPreProduction${preProduction.id}Tooltip${linkingJobId}`"
              ></text-popover>
            </a>
            <div
              class="vms-link-pre-production__remove vms-link-pre-production__align--middle vd-margin-left-extra-small vd-display-inline-block"
              @click="removeItem(preProduction.id)"
            >
              <span
                class="vd-icon-close-circle vms-link-pre-production__icons--remove"
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import { mapGetters, mapActions } from 'vuex'
import { filter, isNil, find, flatten, map, isEmpty } from 'lodash'
import TextPopover from '@components/tooltips/text-popover'
import SweetAlert from '@plugins/sweet-alert'

export default {
  components: {
    TextPopover,
  },

  directives: {
    ClickOutside,
  },

  props: {
    linkedPreProductions: {
      type: Array,
      required: false,
      default: () => {
        return []
      },
    },
    linkingJobId: {
      type: Number,
      required: true,
      default: null,
    },
    isOnHold: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      showPreProductionDropdownList: false,
      showPreProductionTypeDropdownList: false,
      selectedPreProductions: [],
    }
  },

  computed: {
    ...mapGetters({
      preProductionTypes: 'project/pre-production/preProductionTypes',
      preProductions: 'project/pre-production/preProductions',
    }),

    hasLinkedPreProductions() {
      return !isEmpty(this.linkedPreProductions)
    },

    isSubmitButtonDisabled() {
      return isEmpty(this.selectedPreProductions)
    },

    nonLinkedPreProductions() {
      const linkedPreProductions = this.linkedPreProductions
      const allPreProductions = flatten(map(this.preProductions, 'items'))

      return filter(allPreProductions, (preProduction) => {
        return isNil(
          find(linkedPreProductions, (linkedPreProduction) => {
            if (preProduction.id === linkedPreProduction.id) {
              return true
            }

            return false
          })
        )
      })
    },
  },

  methods: {
    ...mapActions({
      updateSelectedPreProductionType:
        'project/pre-production/updateSelectedPreProductionType',
      showAddNewPreProductionsForm:
        'project/pre-production/showAddNewPreProductionsForm',
      showAddNewPreProductionsFormWithLinkingJob:
        'project/pre-production/showAddNewPreProductionsFormWithLinkingJob',
    }),

    handleSubmit() {
      this.$emit('update:selected-pre-productions', this.selectedPreProductions)
      this.showPreProductionDropdownList = false
    },

    showNewPreProductionForm(selectedType) {
      this.updateSelectedPreProductionType(selectedType)
      this.showAddNewPreProductionsFormWithLinkingJob(this.linkingJobId)
      this.$router.push({
        name: 'project.details.pre-production',
      })
    },

    closePreProductionList() {
      this.showPreProductionDropdownList = false
    },

    closePreProductionTypeList() {
      this.showPreProductionTypeDropdownList = false
    },

    toggleShowPreProductionDropdownList() {
      this.selectedPreProductions = []
      this.showPreProductionDropdownList = !this.showPreProductionDropdownList
    },

    toggleShowPreProductionTypeDropdownList() {
      if (this.isOnHold) {
        SweetAlert.fire({
          title: 'Project On Hold',
          html: `<div class="vd-margin-top-small">Take project off hold before adding a pre-production job.</div>`,
          showCancelButton: false,
          confirmButtonText: 'Okay',
        })
        return
      }

      this.showPreProductionTypeDropdownList = !this
        .showPreProductionTypeDropdownList
    },

    removeItem(preProduction) {
      this.$emit('remove:selected-pre-production', preProduction)
      this.selectedPreProductions = []
    },

    getTruncatedLabel(text, size) {
      if (text.length >= size) {
        return text.substring(0, size) + '...'
      }

      return text
    },
  },
}
</script>

<style lang="scss">
@import '@styles/views/project/link-pre-production';
</style>
