import EventTypes from '@configs/event-types'
import { find } from 'lodash'

export default {
  methods: {
    getJobTypeByEventTypeId(id) {
      const eventType = find(EventTypes.events, { id })
      return eventType?.jobType || null
    },
  },
}
