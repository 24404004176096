<template>
  <v-app>
    <add-edit-form-component
      resource-type="FilmingDetails"
      :is-fullscreen="isMobileScreen"
      :is-read-only="isViewMode"
      :disable-save-button="isOnHoldLoading"
      :disable-cancel-button="isOnHoldLoading"
      :disable-delete-button="isOnHoldLoading"
      @saveResource="saveFilmingDetails"
      @cancelForm="cancelFilmingDetails"
      @deleteResource="deleteShootJob"
    >
      <div v-if="isMobileScreen" slot="form-header" class="row">
        <h3 class="vd-margin-bottom-0 vd-padding-top-medium vd-h3 col-6">
          Filming Details
        </h3>
        <div class="vd-text-align-right col-6">
          <button
            v-if="isViewMode"
            id="toggleViewModeButton"
            class="vd-btn-grey vd-a vd-btn-small vd-margin-right-medium"
            @click="toggleViewMode"
          >
            Edit
          </button>
          <span
            class="vd-icon-close vms-modal__close"
            @click="cancelFilmingDetails"
          ></span>
        </div>
      </div>

      <div slot="form-content">
        <div v-if="availableExtrasLoading">
          <default-loader></default-loader>
        </div>

        <div v-else>
          <div
            v-if="!isDateTimeChangeAllowed"
            :class="[
              'row no-gutters',
              isMobileScreen
                ? 'vd-padding-bottom-small'
                : 'vd-padding-inline-medium vd-padding-top-small',
            ]"
          >
            <div class="col-12 vd-red vd-text-small">
              Modify duration or dates by using the calendar
            </div>
          </div>
          <div
            :class="[
              'vms-filming-details__form',
              isMobileScreen ? 'row' : 'vd-margin-top-large',
            ]"
          >
            <div
              class="vms-filming-details__form--group vd-padding-inline-medium"
            >
              <div class="vd-margin-bottom-30">
                <div
                  v-if="isViewMode"
                  class="vms-filming-details__form--read-only vd-border-light-grey"
                >
                  <div class="vd-dark-grey vd-h5 vd-margin-bottom-small">
                    Shoot Name
                  </div>
                  <div class="vd-margin-bottom-extra-small">
                    {{ shootingNameValue }}
                  </div>
                </div>

                <input-component
                  v-else
                  type="text"
                  name="filmingDetailsShootingName"
                  label="Shoot Name"
                  placeholder="Choose a memorable name"
                  :required="true"
                  :value="shootingNameValue"
                  :container-custom-classes="[
                    setInputValidationClass('shootingName', isEditType),
                  ]"
                  @formInput="handleInputUpdate($event, 'shootingName')"
                ></input-component>
              </div>

              <div class="vd-margin-bottom-30">
                <div
                  v-if="isViewMode"
                  class="vms-filming-details__form--read-only vd-border-light-grey"
                >
                  <div class="vd-dark-grey vd-h5 vd-margin-bottom-small">
                    Street Address
                  </div>
                  <div class="vd-margin-bottom-extra-small">
                    {{ streetValue }}
                  </div>
                </div>

                <input-component
                  v-else
                  ref="street"
                  type="text"
                  name="filmingDetailsStreet"
                  label="Street Address"
                  placeholder="Enter an address to start the search"
                  :required="true"
                  :value="streetValue"
                  :is-google-maps-type="true"
                  :container-custom-classes="[
                    setInputValidationClass('street', isEditType),
                  ]"
                  @formInput="handleInputUpdate($event, 'street')"
                ></input-component>
              </div>

              <div class="vd-margin-bottom-30">
                <div
                  v-if="isViewMode"
                  class="vms-filming-details__form--read-only vd-border-light-grey"
                >
                  <div class="vd-dark-grey vd-h5 vd-margin-bottom-small">
                    Suburb
                  </div>
                  <div class="vd-margin-bottom-extra-small">
                    {{ suburbValue }}
                  </div>
                </div>

                <input-component
                  v-else
                  ref="suburb"
                  type="text"
                  name="filmingDetailsSuburb"
                  label="Suburb"
                  placeholder="Enter shoot suburb"
                  :required="true"
                  :value="suburbValue"
                  :container-custom-classes="
                    setInputValidationClass('suburb', isEditType)
                  "
                  @formInput="handleInputUpdate($event, 'suburb')"
                ></input-component>
              </div>

              <div class="row">
                <div class="col-6 vd-margin-bottom-30">
                  <div
                    v-if="isViewMode"
                    class="vms-filming-details__form--read-only vd-border-light-grey"
                  >
                    <div class="vd-dark-grey vd-h5 vd-margin-bottom-small">
                      State
                    </div>
                    <div class="vd-margin-bottom-extra-small">
                      {{ state ? states[state - 1].name : '' }}
                    </div>
                  </div>

                  <template v-else>
                    <div
                      ref="state"
                      :class="[
                        'vd-field vd-required',
                        setInputValidationClass('state', isEditType),
                      ]"
                    >
                      <label>State</label>

                      <select
                        id="filmingDetailsStateSelect"
                        ref="stateSelect"
                        v-model="state"
                        name="state"
                        required
                        @change="
                          handleInputUpdate($event.target.value, 'state')
                        "
                      >
                        <option :value="null" disabled selected>
                          Select State
                        </option>
                        <option
                          v-for="locationState in states"
                          :key="locationState.id"
                          :value="locationState.id"
                        >
                          {{ locationState.name }}
                        </option>
                      </select>
                    </div>
                  </template>
                </div>

                <div class="col-6 vd-margin-bottom-30">
                  <div
                    v-if="isViewMode"
                    class="vms-filming-details__form--read-only vd-border-light-grey"
                  >
                    <div class="vd-dark-grey vd-h5 vd-margin-bottom-small">
                      Postcode
                    </div>
                    <div class="vd-margin-bottom-extra-small">
                      {{ postcodeValue }}
                    </div>
                  </div>

                  <input-component
                    v-else
                    ref="postcode"
                    type="text"
                    name="filmingDetailsPostcode"
                    label="Postcode"
                    placeholder="Enter postcode"
                    :required="true"
                    :value="postcodeValue"
                    :container-custom-classes="[
                      setInputValidationClass('postcode', isEditType),
                    ]"
                    @formInput="handleInputUpdate($event, 'postcode')"
                  ></input-component>
                </div>
              </div>
            </div>

            <div
              v-if="isDateTimeChangeAllowed"
              class="vms-filming-details__form--group vd-padding-inline-medium"
            >
              <div class="vd-margin-bottom-30">
                <div
                  v-if="isViewMode"
                  class="vms-filming-details__form--read-only vd-border-light-grey"
                >
                  <div class="vd-dark-grey vd-h5 vd-margin-bottom-small">
                    Production Date
                  </div>
                  <div class="vd-margin-bottom-extra-small">
                    {{ productionDate }}
                  </div>
                </div>

                <template v-else>
                  <div
                    :class="[
                      'vd-field vd-required',
                      setInputValidationClass('productionDate', isEditType),
                    ]"
                  >
                    <label class="vd-position-relative"
                      >Choose Date (Must be in the future)</label
                    >

                    <flat-pickr
                      id="filmingDetailsProductionDateInput"
                      v-model="productionDate"
                      :config="productionDateConfig"
                      class="vd-border-light-grey vd-background-transparent vd-input vd-input-valid vd-input-filled vd-required"
                      placeholder="Choose Date (Must be in the future)"
                      name="productionDate"
                      @input="handleInputUpdate($event, 'productionDate')"
                    >
                    </flat-pickr>
                  </div>
                </template>
              </div>

              <div class="vd-margin-bottom-30">
                <div
                  v-if="isViewMode"
                  class="vms-filming-details__form--read-only vd-border-light-grey"
                >
                  <div class="vd-dark-grey vd-h5 vd-margin-bottom-small">
                    Time
                  </div>
                  <div class="vd-margin-bottom-extra-small">
                    {{ startingTime | formatTime('HH:mm:ss', 'hh:mm A') }}
                  </div>
                </div>

                <form-time-picker
                  v-else
                  id="filmingDetailsStartingTimeInput"
                  v-model="startingTime"
                  :selected-date="productionDate"
                  :class="[
                    'vd-field vd-required',
                    setInputValidationClass('startingTime', isEditType),
                  ]"
                  label="Time"
                  placeholder="Select time"
                  @change="handleInputUpdate($event, 'startingTime')"
                ></form-time-picker>
              </div>

              <div class="vd-margin-bottom-30">
                <div
                  v-if="isViewMode"
                  class="vms-filming-details__form--read-only vd-border-light-grey"
                >
                  <div class="vd-dark-grey vd-h5 vd-margin-bottom-small">
                    Shoot Duration
                  </div>
                  <div class="vd-margin-bottom-extra-small">
                    {{ shootDurationLabel }}
                  </div>
                </div>

                <template v-else-if="durationList">
                  <v-select
                    id="filmingDetailsShootDurationSelect"
                    v-model="shootDuration"
                    placeholder="Select duration"
                    persistent-placeholder
                    :class="[
                      'vd-field vd-required ',
                      setInputValidationClass('shootDuration', isEditType),
                    ]"
                    :items="durationList"
                    :hide-selected="true"
                    :required="true"
                    :menu-props="menuProps"
                    label="Shoot Duration"
                    no-data-text="No results to display"
                    @change="handleInputUpdate($event, 'shootDuration')"
                  >
                  </v-select>
                </template>
              </div>
            </div>

            <div
              class="vms-filming-details__form--group vd-padding-inline-medium vd-margin-bottom-30"
            >
              <product-element-extras
                id="filmingDetailsProductElementExtrasSelect"
                :available-extras="availableExtras"
                :product-elements-list="filmingDetailsExtras"
                :is-edit-type="isEditType"
                :hovered-extra="hoveredExtra"
                :selected-product-elements="selectedProductElements"
                :has-extras="hasExtras"
                :can-update-extras="!isViewMode"
                :container-custom-classes="productExtrasCustomClasses"
                @update:selected-extras="updateSelectedExtras"
                @hover:selected-extras="handleExtraHover"
                @hover:remove-selected-extra="removeSelectedExtra"
              ></product-element-extras>
            </div>

            <div
              class="vms-filming-details__form--group vd-padding-inline-medium vd-margin-bottom-30"
            >
              <div
                v-if="isViewMode"
                class="vms-filming-details__form--read-only"
              >
                <div class="vd-dark-grey vd-h5 vd-margin-bottom-small">
                  General Comments
                </div>
                <!-- eslint-disable vue/no-v-html -->
                <div
                  class="vd-margin-bottom-extra-small"
                  v-html="generalComments"
                ></div>
              </div>

              <template v-else>
                <text-editor
                  id="filmingDetailsGeneralCommentsTextArea"
                  label="General Comments"
                  :class="['vd-field', generalComments ? 'vd-input-valid' : '']"
                  :content="generalComments"
                  @update:text-editor-content="handleGeneralCommentsUpdated"
                ></text-editor>
              </template>
            </div>
          </div>
        </div>
      </div>

      <template slot="additional-buttons">
        <button
          v-if="canHoldJob"
          :class="[
            'vms-project__form--btn vd-btn-white',
            isMobileScreen
              ? 'vms-project__form--btn__mobile vd-btn-small vd-margin-right-small'
              : 'vd-btn-medium vd-margin-right-medium',
          ]"
          :disabled="disableHoldButton"
          @click="handleClickHoldButton"
        >
          <span v-if="onHoldLoading"> Loading... </span>
          <span v-else> Hold </span>
        </button>
      </template>
    </add-edit-form-component>
  </v-app>
</template>

<style>
@import '~flatpickr/dist/flatpickr.css';
</style>

<script>
import { mapGetters, mapActions } from 'vuex'
import AddEditFormComponent from '@views/project/components/forms/form-main'
import InputComponent from '@components/form/form-input'
import FormMixin from '@mixins/forms-mixin'
import FiltersMixin from '@mixins/filters-mixin'
import momentTz from 'moment-timezone'
import {
  join,
  isEmpty,
  find,
  remove,
  forEach,
  isEqual,
  sortBy,
  cloneDeep,
  isNull,
  isNil,
  map,
  entries,
} from 'lodash'
import { required, minLength } from 'vuelidate/lib/validators'
import StatesConstants from '@configs/states'
import ShootDurationsConstants from '@configs/shoot-durations'
import SweetAlert from '@plugins/sweet-alert'
import FlatPickr from 'vue-flatpickr-component'
import DefaultLoader from '@components/loaders/default-loader'
import ProductElementExtras from '@views/project/components/forms/components/product-element-extras'
import TextEditor from '@components/text-editor/text-editor'
import ApiCustomIncludes from '@configs/api-custom-includes'
import VmsConstants from '@configs/vms-constants'
import Vuetify from 'vuetify'
import FormTimePicker from '@components/form/form-time-picker'

export default {
  components: {
    AddEditFormComponent,
    InputComponent,
    FlatPickr,
    DefaultLoader,
    ProductElementExtras,
    TextEditor,
    FormTimePicker,
  },

  mixins: [FormMixin, FiltersMixin],

  vuetify: new Vuetify(),

  inject: ['formActionType'],

  props: {
    isViewMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      shootingName: '',
      street: '',
      suburb: '',
      state: null,
      postcode: '',
      productionDate: null,
      startingTime: null,
      shootDuration: 60,
      generalComments: '',
      selectedExtras: [],
      hoveredExtra: null,
      states: StatesConstants.states,
      shootDurationList: ShootDurationsConstants.shootDurations,
      selectedProductElements: [],
      defaultPayload: {},
      onHold: null,
      onHoldLoading: false,
      menuProps: {
        auto: true,
        closeOnClick: true,
        offsetY: true,
      },
    }
  },

  validations: {
    shootingName: {
      required,
      minLength: minLength(1),
    },

    street: {
      required,
      minLength: minLength(1),
    },

    suburb: {
      required,
      minLength: minLength(1),
    },

    state: {
      required,
    },

    postcode: {
      required,
    },

    shootDuration: {
      required,
    },

    productionDate: {
      required,
    },

    startingTime: {
      required,
    },
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      selectedFilmingDetails: 'project/shoot/selectedFilmingDetails',
      filmingDetailsExtras: 'project/shoot/filmingDetailsExtras',
      availableExtrasLoading: 'project/shoot/availableExtrasLoading',
      isMobileScreen: 'common/isMobileScreen',
    }),

    isEditType() {
      return this.formActionType === 'edit'
    },

    isFormInvalid() {
      return this.$v.$invalid
    },

    productionDateConfig() {
      return {
        enableTime: false,
        allowInput: true,
        altFormat: 'd M Y',
        dateFormat: 'd M Y',
        altInput: true,
        disableMobile: 'true',
        minDate: 'today',
      }
    },

    shootingNameValue() {
      return this.isEditType
        ? this.selectedFilmingDetails
          ? this.selectedFilmingDetails.name
          : ''
        : ''
    },

    streetValue() {
      return this.isEditType
        ? this.selectedFilmingDetails
          ? this.selectedFilmingDetails.street
          : ''
        : ''
    },

    suburbValue() {
      return this.isEditType
        ? this.selectedFilmingDetails
          ? this.selectedFilmingDetails.suburb
          : ''
        : ''
    },

    postcodeValue() {
      return this.isEditType
        ? this.selectedFilmingDetails
          ? this.selectedFilmingDetails.postcode
          : ''
        : ''
    },

    availableExtras() {
      return this.isEditType
        ? this.selectedFilmingDetails
          ? this.selectedFilmingDetails.available_extras
          : []
        : this.filmingDetailsExtras
    },

    hasExtras() {
      return this.selectedFilmingDetails
        ? !isEmpty(this.selectedFilmingDetails.product_element_types)
        : false
    },

    changedPayload() {
      return {
        shootingName: this.shootingName,
        street: this.street,
        suburb: this.suburb,
        state: this.state,
        postcode: this.postcode,
        productionDate: this.productionDate,
        startingTime: this.startingTime,
        shootDuration: parseInt(this.shootDuration),
        generalComments: this.generalComments,
        selectedExtras: join(sortBy(this.selectedExtras)),
        selectedProductElements: join(
          sortBy(
            this.selectedProductElements?.map((selectedProductElement) => {
              return selectedProductElement.id
            })
          )
        ),
      }
    },

    isValidToHold() {
      return !isEmpty(this.shootingName)
    },

    disableHoldButton() {
      return (
        !this.isValidToHold ||
        this.onHoldLoading ||
        (this.selectedFilmingDetails?.order_job_status.id ===
          VmsConstants.orderJobStatuses.ORDER_JOB_ON_HOLD &&
          isEqual(this.defaultPayload, this.changedPayload))
      )
    },

    isOnHoldLoading() {
      return this.onHoldLoading
    },

    canHoldJob() {
      if (
        (!isNil(this.selectedFilmingDetails) &&
          this.selectedFilmingDetails?.order_job_status?.id ===
            VmsConstants.orderJobStatuses.ORDER_JOB_ON_HOLD) ||
        !this.isEditType
      ) {
        return true
      }

      return false
    },

    productExtrasCustomClasses() {
      return (!isEmpty(this.selectedProductElements) ||
        !isEmpty(this.selectedExtras)) &&
        !this.isViewMode
        ? 'vd-input-valid'
        : ''
    },

    shootDurationLabel() {
      return this.shootDurationList[this.shootDuration]
    },

    isDateTimeChangeAllowed() {
      return (
        !this.isEditType ||
        this.project?.project_status_id ===
          VmsConstants.projectStatuses.PROJECT_STATUS_ID_DRAFT ||
        this.selectedFilmingDetails?.order_job_status.id ===
          VmsConstants.orderJobStatuses.ORDER_JOB_ON_HOLD
      )
    },

    durationList() {
      return map(
        entries(ShootDurationsConstants.shootDurations),
        ([key, value]) => ({
          text: value,
          value: Number(key),
        })
      )
    },
  },

  watch: {
    isFormInvalid(val) {
      this.updateFormSaveBtnDisabled(val)
    },

    street(val) {
      if (typeof val === 'object' && !isEmpty(val)) {
        this.updateAddressFields(val)
      }
    },

    changedPayload(val) {
      this.updateFormSaveBtnDisabled(
        (isEqual(this.defaultPayload, val) &&
          this.selectedFilmingDetails?.order_job_status?.id !==
            VmsConstants.orderJobStatuses.ORDER_JOB_ON_HOLD) ||
          this.isFormInvalid
      )
    },
  },

  beforeMount() {
    this.selectedProductElements = cloneDeep(
      this.selectedFilmingDetails?.product_element_types
    )
  },

  mounted() {
    if (this.selectedFilmingDetails && this.isEditType) {
      this.shootingName = this.selectedFilmingDetails.name
      this.street = this.selectedFilmingDetails.street
      this.suburb = this.selectedFilmingDetails.suburb
      this.state = this.selectedFilmingDetails.state_id
      this.postcode = this.selectedFilmingDetails.postcode
      this.shootDuration = this.selectedFilmingDetails.calendar_time
      this.productionDate = momentTz
        .unix(this.selectedFilmingDetails.production_date)
        .format('DD MMM YYYY')
      this.startingTime = momentTz
        .unix(this.selectedFilmingDetails.production_date)
        .format('HH:mm:ss')
      this.generalComments = isEmpty(this.selectedFilmingDetails.external_note)
        ? null
        : this.selectedFilmingDetails.external_note

      // validate when in edit mode
      this.$v.$touch()
    }

    if (!this.isEditType && isEmpty(this.filmingDetailsExtras)) {
      this.getShootProductElements({
        project_id: this.project.id,
        includes: ApiCustomIncludes.filmingDetailsList,
      })
    }

    this.initDefaultPayload()
  },

  methods: {
    ...mapActions({
      getShootProductElements: 'project/shoot/getShootProductElements',
      updateSelectedFilmingDetails:
        'project/shoot/updateSelectedFilmingDetails',
      showEditFilmingDetailsForm: 'project/shoot/showEditFilmingDetailsForm',
      showAddNewFilmingDetailsForm:
        'project/shoot/showAddNewFilmingDetailsForm',
      updateFormSaveBtnDisabled: 'project/updateFormSaveBtnDisabled',
      updateFilmingDetails: 'project/shoot/updateFilmingDetails',
      saveNewFilmingDetails: 'project/shoot/saveNewFilmingDetails',
      deleteFilmingDetails: 'project/shoot/deleteFilmingDetails',
    }),

    removeSelectedExtra(productElement) {
      // Check if the product element being removed is a current selected product element associated with the current project
      const isSelectedProductElements =
        find(this.selectedProductElements, {
          id: productElement.id,
        }) !== undefined

      if (
        isSelectedProductElements &&
        productElement.name !== 'Camera Operator'
      ) {
        remove(this.selectedProductElements, {
          id: productElement.id,
        })
      }

      this.$forceUpdate() // to re-render the v-for without reloading the whole page
    },

    updateAddressFields(addressData) {
      this.state = find(this.states, (val) => {
        return val.name === addressData.state
      }).id

      this.$refs['postcode'].inputValue = addressData.postcode
      this.$refs['street'].inputValue = addressData.street
      this.$refs['suburb'].inputValue = addressData.suburb
    },

    handleExtraHover(productElement) {
      this.hoveredExtra =
        productElement && productElement.name !== 'Camera Operator'
          ? productElement
          : null

      this.$forceUpdate() // to re-render the v-for without reloading the whole page
    },

    updateSelectedExtras(selectedExtras) {
      this.selectedExtras = selectedExtras
    },

    cancelFilmingDetails() {
      if (this.isViewMode && this.isEditType) {
        this.showEditFilmingDetailsForm(false)
        this.updateSelectedFilmingDetails(null)
        return
      }

      const vm = this

      SweetAlert.fire({
        title: 'Warning!',
        html: 'Are you sure you want to cancel your changes?',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        heightAuto: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          if (vm.isEditType) {
            this.showEditFilmingDetailsForm(false)
            this.updateSelectedFilmingDetails(null)
          } else {
            this.showAddNewFilmingDetailsForm(false)
          }
        }
      })
    },

    generateRequestPayload() {
      const payload = {
        name: this.shootingName,
        street: this.street,
        suburb: this.suburb,
        state_id: this.state,
        postcode: this.postcode,
        production_date: this.productionDate
          ? momentTz(this.productionDate, 'DD MMM YYYY').format('YYYY-MM-DD')
          : null,
        production_time: this.startingTime,
        external_note: this.generalComments,
        duration: this.shootDuration,
      }

      // Get IDs of the current selected product element types
      const selectedProductElementTypeIds = []

      forEach(this.selectedProductElements, (selectedProductElement) => {
        selectedProductElementTypeIds.push(selectedProductElement.id)
      })

      // Merge selected extras and current product elements in array
      payload['product_element_type_ids'] = join(selectedProductElementTypeIds)

      payload['additional_extras'] = !isEmpty(this.selectedExtras)
        ? join(this.selectedExtras)
        : []

      if (!isNull(this.onHold) && this.onHold) {
        payload['on_hold'] = 1
      }

      return payload
    },

    saveFilmingDetails() {
      const payload = this.generateRequestPayload()

      if (this.isEditType) {
        const orderJobId = this.selectedFilmingDetails.order_job_id

        this.updateFilmingDetails({
          project_id: this.project.id,
          name: this.shootingName,
          order_job_id: orderJobId,
          includes: ApiCustomIncludes.filmingDetailsList,
          page: this.$route.query ? this.$route.query.page : 1,
          payload: payload,
        })
      } else {
        this.saveNewFilmingDetails({
          project_id: this.project.id,
          name: this.shootingName,
          includes: ApiCustomIncludes.filmingDetailsList,
          page: this.$route.query ? this.$route.query.page : 1,
          payload: payload,
        })
      }
    },

    deleteShootJob() {
      this.deleteFilmingDetails({
        project_id: this.project.id,
        shoot_job_id: this.selectedFilmingDetails.order_job_id,
        includes: null,
        name: this.shootingName,
      })
      this.updateFormSaveBtnDisabled(true)
    },

    handleGeneralCommentsUpdated(comments) {
      this.generalComments = comments
    },

    initDefaultPayload() {
      this.defaultPayload = {
        shootingName: this.shootingName,
        street: this.street,
        suburb: this.suburb,
        state: this.state,
        postcode: this.postcode,
        productionDate: this.productionDate,
        startingTime: this.startingTime,
        shootDuration: parseInt(this.shootDuration),
        generalComments: this.generalComments,
        selectedExtras: join(sortBy(this.selectedExtras)),
        selectedProductElements: join(
          sortBy(
            this.selectedProductElements?.map((selectedProductElement) => {
              return selectedProductElement.id
            })
          )
        ),
      }
    },

    toggleViewMode() {
      this.$emit('edit')
    },

    handleClickHoldButton() {
      this.onHoldLoading = true
      this.onHold = true
      this.saveFilmingDetails()
    },
  },
}
</script>
