var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('add-edit-form-component',{attrs:{"resource-type":"FilmingDetails","is-fullscreen":_vm.isMobileScreen,"is-read-only":_vm.isViewMode,"disable-save-button":_vm.isOnHoldLoading,"disable-cancel-button":_vm.isOnHoldLoading,"disable-delete-button":_vm.isOnHoldLoading},on:{"saveResource":_vm.saveFilmingDetails,"cancelForm":_vm.cancelFilmingDetails,"deleteResource":_vm.deleteShootJob}},[(_vm.isMobileScreen)?_c('div',{staticClass:"row",attrs:{"slot":"form-header"},slot:"form-header"},[_c('h3',{staticClass:"vd-margin-bottom-0 vd-padding-top-medium vd-h3 col-6"},[_vm._v(" Filming Details ")]),_c('div',{staticClass:"vd-text-align-right col-6"},[(_vm.isViewMode)?_c('button',{staticClass:"vd-btn-grey vd-a vd-btn-small vd-margin-right-medium",attrs:{"id":"toggleViewModeButton"},on:{"click":_vm.toggleViewMode}},[_vm._v(" Edit ")]):_vm._e(),_c('span',{staticClass:"vd-icon-close vms-modal__close",on:{"click":_vm.cancelFilmingDetails}})])]):_vm._e(),_c('div',{attrs:{"slot":"form-content"},slot:"form-content"},[(_vm.availableExtrasLoading)?_c('div',[_c('default-loader')],1):_c('div',[(!_vm.isDateTimeChangeAllowed)?_c('div',{class:[
            'row no-gutters',
            _vm.isMobileScreen
              ? 'vd-padding-bottom-small'
              : 'vd-padding-inline-medium vd-padding-top-small' ]},[_c('div',{staticClass:"col-12 vd-red vd-text-small"},[_vm._v(" Modify duration or dates by using the calendar ")])]):_vm._e(),_c('div',{class:[
            'vms-filming-details__form',
            _vm.isMobileScreen ? 'row' : 'vd-margin-top-large' ]},[_c('div',{staticClass:"vms-filming-details__form--group vd-padding-inline-medium"},[_c('div',{staticClass:"vd-margin-bottom-30"},[(_vm.isViewMode)?_c('div',{staticClass:"vms-filming-details__form--read-only vd-border-light-grey"},[_c('div',{staticClass:"vd-dark-grey vd-h5 vd-margin-bottom-small"},[_vm._v(" Shoot Name ")]),_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_vm._v(" "+_vm._s(_vm.shootingNameValue)+" ")])]):_c('input-component',{attrs:{"type":"text","name":"filmingDetailsShootingName","label":"Shoot Name","placeholder":"Choose a memorable name","required":true,"value":_vm.shootingNameValue,"container-custom-classes":[
                  _vm.setInputValidationClass('shootingName', _vm.isEditType) ]},on:{"formInput":function($event){return _vm.handleInputUpdate($event, 'shootingName')}}})],1),_c('div',{staticClass:"vd-margin-bottom-30"},[(_vm.isViewMode)?_c('div',{staticClass:"vms-filming-details__form--read-only vd-border-light-grey"},[_c('div',{staticClass:"vd-dark-grey vd-h5 vd-margin-bottom-small"},[_vm._v(" Street Address ")]),_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_vm._v(" "+_vm._s(_vm.streetValue)+" ")])]):_c('input-component',{ref:"street",attrs:{"type":"text","name":"filmingDetailsStreet","label":"Street Address","placeholder":"Enter an address to start the search","required":true,"value":_vm.streetValue,"is-google-maps-type":true,"container-custom-classes":[
                  _vm.setInputValidationClass('street', _vm.isEditType) ]},on:{"formInput":function($event){return _vm.handleInputUpdate($event, 'street')}}})],1),_c('div',{staticClass:"vd-margin-bottom-30"},[(_vm.isViewMode)?_c('div',{staticClass:"vms-filming-details__form--read-only vd-border-light-grey"},[_c('div',{staticClass:"vd-dark-grey vd-h5 vd-margin-bottom-small"},[_vm._v(" Suburb ")]),_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_vm._v(" "+_vm._s(_vm.suburbValue)+" ")])]):_c('input-component',{ref:"suburb",attrs:{"type":"text","name":"filmingDetailsSuburb","label":"Suburb","placeholder":"Enter shoot suburb","required":true,"value":_vm.suburbValue,"container-custom-classes":_vm.setInputValidationClass('suburb', _vm.isEditType)},on:{"formInput":function($event){return _vm.handleInputUpdate($event, 'suburb')}}})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 vd-margin-bottom-30"},[(_vm.isViewMode)?_c('div',{staticClass:"vms-filming-details__form--read-only vd-border-light-grey"},[_c('div',{staticClass:"vd-dark-grey vd-h5 vd-margin-bottom-small"},[_vm._v(" State ")]),_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_vm._v(" "+_vm._s(_vm.state ? _vm.states[_vm.state - 1].name : '')+" ")])]):[_c('div',{ref:"state",class:[
                      'vd-field vd-required',
                      _vm.setInputValidationClass('state', _vm.isEditType) ]},[_c('label',[_vm._v("State")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.state),expression:"state"}],ref:"stateSelect",attrs:{"id":"filmingDetailsStateSelect","name":"state","required":""},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.state=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.handleInputUpdate($event.target.value, 'state')}]}},[_c('option',{attrs:{"disabled":"","selected":""},domProps:{"value":null}},[_vm._v(" Select State ")]),_vm._l((_vm.states),function(locationState){return _c('option',{key:locationState.id,domProps:{"value":locationState.id}},[_vm._v(" "+_vm._s(locationState.name)+" ")])})],2)])]],2),_c('div',{staticClass:"col-6 vd-margin-bottom-30"},[(_vm.isViewMode)?_c('div',{staticClass:"vms-filming-details__form--read-only vd-border-light-grey"},[_c('div',{staticClass:"vd-dark-grey vd-h5 vd-margin-bottom-small"},[_vm._v(" Postcode ")]),_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_vm._v(" "+_vm._s(_vm.postcodeValue)+" ")])]):_c('input-component',{ref:"postcode",attrs:{"type":"text","name":"filmingDetailsPostcode","label":"Postcode","placeholder":"Enter postcode","required":true,"value":_vm.postcodeValue,"container-custom-classes":[
                    _vm.setInputValidationClass('postcode', _vm.isEditType) ]},on:{"formInput":function($event){return _vm.handleInputUpdate($event, 'postcode')}}})],1)])]),(_vm.isDateTimeChangeAllowed)?_c('div',{staticClass:"vms-filming-details__form--group vd-padding-inline-medium"},[_c('div',{staticClass:"vd-margin-bottom-30"},[(_vm.isViewMode)?_c('div',{staticClass:"vms-filming-details__form--read-only vd-border-light-grey"},[_c('div',{staticClass:"vd-dark-grey vd-h5 vd-margin-bottom-small"},[_vm._v(" Production Date ")]),_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_vm._v(" "+_vm._s(_vm.productionDate)+" ")])]):[_c('div',{class:[
                    'vd-field vd-required',
                    _vm.setInputValidationClass('productionDate', _vm.isEditType) ]},[_c('label',{staticClass:"vd-position-relative"},[_vm._v("Choose Date (Must be in the future)")]),_c('flat-pickr',{staticClass:"vd-border-light-grey vd-background-transparent vd-input vd-input-valid vd-input-filled vd-required",attrs:{"id":"filmingDetailsProductionDateInput","config":_vm.productionDateConfig,"placeholder":"Choose Date (Must be in the future)","name":"productionDate"},on:{"input":function($event){return _vm.handleInputUpdate($event, 'productionDate')}},model:{value:(_vm.productionDate),callback:function ($$v) {_vm.productionDate=$$v},expression:"productionDate"}})],1)]],2),_c('div',{staticClass:"vd-margin-bottom-30"},[(_vm.isViewMode)?_c('div',{staticClass:"vms-filming-details__form--read-only vd-border-light-grey"},[_c('div',{staticClass:"vd-dark-grey vd-h5 vd-margin-bottom-small"},[_vm._v(" Time ")]),_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_vm._v(" "+_vm._s(_vm._f("formatTime")(_vm.startingTime,'HH:mm:ss', 'hh:mm A'))+" ")])]):_c('form-time-picker',{class:[
                  'vd-field vd-required',
                  _vm.setInputValidationClass('startingTime', _vm.isEditType) ],attrs:{"id":"filmingDetailsStartingTimeInput","selected-date":_vm.productionDate,"label":"Time","placeholder":"Select time"},on:{"change":function($event){return _vm.handleInputUpdate($event, 'startingTime')}},model:{value:(_vm.startingTime),callback:function ($$v) {_vm.startingTime=$$v},expression:"startingTime"}})],1),_c('div',{staticClass:"vd-margin-bottom-30"},[(_vm.isViewMode)?_c('div',{staticClass:"vms-filming-details__form--read-only vd-border-light-grey"},[_c('div',{staticClass:"vd-dark-grey vd-h5 vd-margin-bottom-small"},[_vm._v(" Shoot Duration ")]),_c('div',{staticClass:"vd-margin-bottom-extra-small"},[_vm._v(" "+_vm._s(_vm.shootDurationLabel)+" ")])]):(_vm.durationList)?[_c('v-select',{class:[
                    'vd-field vd-required ',
                    _vm.setInputValidationClass('shootDuration', _vm.isEditType) ],attrs:{"id":"filmingDetailsShootDurationSelect","placeholder":"Select duration","persistent-placeholder":"","items":_vm.durationList,"hide-selected":true,"required":true,"menu-props":_vm.menuProps,"label":"Shoot Duration","no-data-text":"No results to display"},on:{"change":function($event){return _vm.handleInputUpdate($event, 'shootDuration')}},model:{value:(_vm.shootDuration),callback:function ($$v) {_vm.shootDuration=$$v},expression:"shootDuration"}})]:_vm._e()],2)]):_vm._e(),_c('div',{staticClass:"vms-filming-details__form--group vd-padding-inline-medium vd-margin-bottom-30"},[_c('product-element-extras',{attrs:{"id":"filmingDetailsProductElementExtrasSelect","available-extras":_vm.availableExtras,"product-elements-list":_vm.filmingDetailsExtras,"is-edit-type":_vm.isEditType,"hovered-extra":_vm.hoveredExtra,"selected-product-elements":_vm.selectedProductElements,"has-extras":_vm.hasExtras,"can-update-extras":!_vm.isViewMode,"container-custom-classes":_vm.productExtrasCustomClasses},on:{"update:selected-extras":_vm.updateSelectedExtras,"hover:selected-extras":_vm.handleExtraHover,"hover:remove-selected-extra":_vm.removeSelectedExtra}})],1),_c('div',{staticClass:"vms-filming-details__form--group vd-padding-inline-medium vd-margin-bottom-30"},[(_vm.isViewMode)?_c('div',{staticClass:"vms-filming-details__form--read-only"},[_c('div',{staticClass:"vd-dark-grey vd-h5 vd-margin-bottom-small"},[_vm._v(" General Comments ")]),_c('div',{staticClass:"vd-margin-bottom-extra-small",domProps:{"innerHTML":_vm._s(_vm.generalComments)}})]):[_c('text-editor',{class:['vd-field', _vm.generalComments ? 'vd-input-valid' : ''],attrs:{"id":"filmingDetailsGeneralCommentsTextArea","label":"General Comments","content":_vm.generalComments},on:{"update:text-editor-content":_vm.handleGeneralCommentsUpdated}})]],2)])])]),_c('template',{slot:"additional-buttons"},[(_vm.canHoldJob)?_c('button',{class:[
          'vms-project__form--btn vd-btn-white',
          _vm.isMobileScreen
            ? 'vms-project__form--btn__mobile vd-btn-small vd-margin-right-small'
            : 'vd-btn-medium vd-margin-right-medium' ],attrs:{"disabled":_vm.disableHoldButton},on:{"click":_vm.handleClickHoldButton}},[(_vm.onHoldLoading)?_c('span',[_vm._v(" Loading... ")]):_c('span',[_vm._v(" Hold ")])]):_vm._e()])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }