<template>
  <div class="vms-event-modal-fields">
    <events-form-fields-assigned-to
      :is-bulk-action="isBulkAction"
      :disabled="disabled"
      :assign-to-user="assignToUser"
      :assign-to-freelancer="assignToFreelancer"
      v-on="$listeners"
    ></events-form-fields-assigned-to>

    <events-form-fields-schedule
      :disabled="disabled"
      :has-all-day-option="hasAllDayOption"
      :start-date="startDate"
      :start-time="startTime"
      :end-date="endDate"
      :end-time="endTime"
      :is-all-day-event="isAllDayEvent"
      :is-required="isScheduleRequired"
      :is-bulk-action="isBulkAction"
      v-on="$listeners"
    ></events-form-fields-schedule>

    <events-form-fields-general-comments
      v-if="hasGeneralComments"
      :disabled="disabled"
      :general-comments="generalComments"
      v-on="$listeners"
    ></events-form-fields-general-comments>
  </div>
</template>

<script>
import Vuetify from 'vuetify'
import EventsFormFieldsAssignedTo from '@components/modal/events/components/events-form-fields/events-form-fields-assigned-to'
import EventsFormFieldsSchedule from '@components/modal/events/components/events-form-fields/events-form-fields-schedule'
import EventsFormFieldsGeneralComments from '@components/modal/events/components/events-form-fields/events-form-fields-general-comments'

export default {
  vuetify: new Vuetify(),

  components: {
    EventsFormFieldsAssignedTo,
    EventsFormFieldsSchedule,
    EventsFormFieldsGeneralComments,
  },

  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    hasGeneralComments: {
      type: Boolean,
      required: false,
      default: false,
    },

    hasAllDayOption: {
      type: Boolean,
      required: false,
      default: false,
    },

    assignToUser: {
      type: Object,
      required: false,
      default: null,
    },

    assignToFreelancer: {
      type: String,
      required: false,
      default: '',
    },

    startDate: {
      type: String,
      required: false,
      default: '',
    },

    startTime: {
      type: String,
      required: false,
      default: '',
    },

    endDate: {
      type: String,
      required: false,
      default: '',
    },

    endTime: {
      type: String,
      required: false,
      default: '',
    },

    isAllDayEvent: {
      type: Boolean,
      required: false,
      default: false,
    },

    generalComments: {
      type: String,
      required: false,
      default: '',
    },

    isScheduleRequired: {
      type: Boolean,
      required: false,
      default: true,
    },

    isBulkAction: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>
