<template>
  <div>
    <input
      ref="inputClipboardCopyRef"
      type="text"
      :value="inputValue"
      readonly
      :class="[
        'vd-input-clipboard__input vd-padding-block-extra-small vd-padding-left-small vd-padding-right-medium vd-border-radius-4 vd-border-lighter-grey vd-border-width-1',
        inputCustomClass,
      ]"
      @focus="$event.target.select()"
    />
    <button
      id="copyInputBtn"
      :class="['vd-input-clipboard__button', buttonCustomClass]"
      @click="handleClick"
    >
      {{ buttonLabel }}
    </button>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  props: {
    inputValue: {
      type: String,
      required: true,
      default: '',
    },

    inputCustomClass: {
      type: String,
      required: false,
      default: '',
    },

    buttonCustomClass: {
      type: String,
      required: false,
      default: 'vd-btn-blue vd-btn-small',
    },

    buttonLabel: {
      type: String,
      required: false,
      default: 'Copy',
    },
  },
  methods: {
    ...mapActions({
      displayToastAlert: 'common/displayToastAlert',
    }),

    handleClick() {
      this.$refs.inputClipboardCopyRef.focus()
      document.execCommand('copy')
      this.displayToastAlert({
        title: 'Successfully copied to clipboard!',
      })
    },
  },
}
</script>
<style lang="scss">
@import '@styles/components/input-clipboard-copy';
</style>
