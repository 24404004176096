var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('div',{staticClass:"row no-gutters vd-align-center"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{class:[
          'vd-field vd-padding-block-medium vd-padding-inline-small',
          _vm.startDate ? 'vd-input-valid' : '',
          _vm.isRequired ? 'vd-required' : '' ]},[_c('label',{staticClass:"vd-position-relative"},[_vm._v("Start date")]),_c('flat-pickr',{attrs:{"id":"startDate","value":_vm.startDate,"config":_vm.startDateConfig,"disabled":_vm.disabled,"placeholder":"Select date","name":"startDate"},on:{"on-change":function($event){return _vm.handleStartDateChange($event)}}})],1)]),(_vm.isAllDayEvent)?_c('div',{staticClass:"col-lg-6"},[_c('div',{class:[
          'vd-field vd-padding-block-medium vd-padding-inline-small',
          _vm.endDate ? 'vd-input-valid' : '',
          _vm.isRequired ? 'vd-required' : '' ]},[_c('label',{staticClass:"vd-position-relative"},[_vm._v("End date")]),_c('flat-pickr',{attrs:{"id":"endDate","value":_vm.endDate,"config":_vm.endDateConfig,"disabled":_vm.disabled,"placeholder":"Select date","name":"startDate"},on:{"on-change":function($event){return _vm.handleEndDateChange($event)}}})],1)]):[_c('div',{staticClass:"col-md-8 col-lg-5"},[_c('div',{staticClass:"vd-display-flex vd-align-center"},[_c('div',{staticClass:"vd-flex-auto vd-padding-block-medium vd-padding-inline-small"},[_c('v-autocomplete',{class:[
                'vd-field',
                _vm.startTime ? 'vd-input-valid' : '',
                _vm.isRequired ? 'vd-required' : '' ],attrs:{"id":"startTime","value":_vm.startTime,"items":_vm.startTimeOptions,"disabled":_vm.disabled,"menu-props":_vm.menuProps,"placeholder":"Select time","persistent-placeholder":"","label":"Start time:","item-text":"text","item-value":"value","no-data-text":"No results to display"},on:{"change":function($event){return _vm.handleStartTimeChange($event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.text)+" ")]}}])})],1),_c('span',{staticClass:"vd-margin-right-small"},[_vm._v("to")]),_c('div',{staticClass:"vd-input-valid vd-flex-auto vd-padding-block-medium vd-padding-inline-small"},[_c('v-autocomplete',{class:[
                'vd-field',
                _vm.endTime ? 'vd-input-valid' : '',
                _vm.isRequired ? 'vd-required' : '' ],attrs:{"id":"endTime","value":_vm.endTime,"items":_vm.endTimeOptions,"disabled":_vm.disabled,"menu-props":_vm.menuProps,"placeholder":"Select time","persistent-placeholder":"","label":"End time:","item-text":"text","item-value":"value","no-data-text":"No results to display"},on:{"change":function($event){return _vm.handleEndTimeChange($event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.text)+" ")]}},{key:"item",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.text)+" "+_vm._s(_vm._f("addParenthesis")(_vm.getTimeDifference(item.value)))+" ")])]}}])})],1)])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"vd-field vd-padding-small"},[_c('label',[_vm._v("Duration")]),_c('div',{},[_vm._v(_vm._s(_vm.durationText || 0))])])])]],2),(_vm.hasAllDayOption)?_c('div',{staticClass:"vd-padding-small"},[_c('label',{staticClass:"vd-cursor-pointer",attrs:{"for":"isAllDayCheckbox"}},[_c('span',{staticClass:"vd-margin-right-small vd-h5 vd-darker-grey"},[_vm._v("All day event?")]),_c('input',{staticClass:"vd-align-middle",attrs:{"id":"isAllDayCheckbox","disabled":_vm.disabled,"type":"checkbox"},domProps:{"checked":_vm.isAllDayEvent},on:{"change":function($event){return _vm.$emit('update:isAllDayEvent', $event.target.checked)}}})])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }