<template>
  <div>
    <template v-if="canShowPutOnHoldBtn">
      <button
        v-if="isSelectedEventOnHold"
        :class="customButtonClass"
        :disabled="isEventFormLoading"
        @click="handleTakeOffHold"
      >
        Take off hold
      </button>
      <button
        v-else
        :class="customButtonClass"
        :disabled="isEventFormLoading"
        @click="handlePutOnHold"
      >
        Put on hold
      </button>
    </template>

    <button
      v-if="canShowDuplicateBtn"
      :class="customButtonClass"
      :disabled="isEventFormLoading"
      @click="handleDuplicate"
    >
      Duplicate
    </button>
    <button
      :class="customButtonClass"
      :disabled="isEventFormLoading"
      @click="handleDeleteEvent"
    >
      <span v-if="selectedEventsCount === 0">Delete event</span>
      <span v-else
        >Delete {{ selectedEventsCount | pluralizeEnglish('event') }}</span
      >
    </button>
    <button
      v-if="canShowNotes"
      :class="[isMobileScreen ? customButtonClass : blueBtnClass]"
      :disabled="isEventFormLoading"
      @click="$emit('click:notes')"
    >
      <div class="vd-display-flex vd-align-center">
        <i
          v-if="!isMobileScreen"
          class="vd-icon icon-chat vd-h4 vd-margin-right-small"
        ></i>
        <span>Notes</span>
      </div>
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { map, size, includes, filter, some } from 'lodash'
import FiltersMixin from '@mixins/filters-mixin'
import EventTypesMixin from '@mixins/event-types-mixin'
import VmsConstants from '@configs/vms-constants'
import SweetAlert from '@plugins/sweet-alert'

export default {
  mixins: [FiltersMixin, EventTypesMixin],

  props: {
    isBulkAction: {
      type: Boolean,
      default: false,
    },

    customButtonClass: {
      type: String,
      default: '',
    },

    canShowPutOnHoldBtn: {
      type: Boolean,
      required: false,
      default: true,
    },

    canShowNotes: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      selectedEvent: 'calendar/selectedEvent',
      isEventFormLoading: 'calendar/isEventFormLoading',
      bulkActionSelectedEvents: 'calendar/bulkActionSelectedEvents',
      isMobileScreen: 'common/isMobileScreen',
      isSelectedEventOnHold: 'calendar/isSelectedEventOnHold',
    }),

    selectedEventsCount() {
      return size(this.bulkActionSelectedEvents)
    },

    canShowDuplicateBtn() {
      // When isBulkAction, show duplicate button only if some of selected events is not an external event
      if (this.isBulkAction) {
        return some(this.bulkActionSelectedEvents, (event) => {
          return !this.isExternalEvent(event.event_type_id)
        })
      }

      return !this.isExternalEvent(this.selectedEvent.event_type_id)
    },

    blueBtnClass() {
      return 'vms-event-modal-actions__btn vd-margin-left-medium vd-background-light-blue vd-white vd-btn-small'
    },

    eventJobType() {
      return this.getJobTypeByEventTypeId(this.selectedEvent?.event_type?.id)
    },
  },

  methods: {
    ...mapActions({
      holdJobEvent: 'calendar/holdJobEvent',
      deleteEvent: 'calendar/deleteEvent',
      bulkDeleteEvents: 'calendar/bulkDeleteEvents',
      bulkDuplicateEvents: 'calendar/bulkDuplicateEvents',
      unholdJobEvent: 'calendar/unholdJobEvent',
      setEventActionHistory: 'calendar/setEventActionHistory',
    }),

    handlePutOnHold() {
      if (!this.isBulkAction) {
        this.holdJobEvent({
          event_id: this.selectedEvent.id,
          event_job_type: this.eventJobType,
          project_id: this.selectedEvent.project.id,
          order_job_id: this.selectedEvent.order_job_element.order_job_id,
        })
      }
    },

    handleDuplicate() {
      const filteredSelectedEvents = filter(
        this.bulkActionSelectedEvents,
        (event) => {
          return !includes(
            [
              VmsConstants.eventTypes.EVENT_TYPE_ID_GOOGLE_CALENDAR_EVENT,
              VmsConstants.eventTypes.EVENT_TYPE_ID_BAMBOO_EVENT,
            ],
            event.event_type_id
          )
        }
      )

      this.bulkDuplicateEvents({
        payload: {
          event_ids: !this.isBulkAction
            ? [this.selectedEvent.id]
            : map(filteredSelectedEvents, 'id'),
        },
      })
    },

    handleDeleteEvent() {
      const message = this.isBulkAction
        ? `Are you sure you want to bulk delete ${this.selectedEventsCount} selected events?`
        : `Are you sure you want to delete ${this.selectedEvent.name}?`
      const title = this.isBulkAction ? 'Bulk Delete Events' : 'Delete Event'

      SweetAlert.fire({
        title: title,
        html: message,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        heightAuto: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          if (!this.isBulkAction) {
            this.deleteEvent({
              eventId: this.selectedEvent.id,
            })

            this.setEventActionHistory({
              type: 'delete',
              originalPayload: {
                id: this.selectedEvent.id,
                from: this.selectedEvent.from,
              },
            })
          } else {
            this.bulkDeleteEvents({
              payload: {
                event_ids: map(this.bulkActionSelectedEvents, 'id'),
              },
            })

            this.setEventActionHistory({
              type: 'bulkDelete',
              originalPayload: {
                event_ids: map(this.bulkActionSelectedEvents, 'id'),
              },
            })
          }
        }
      })
    },

    isExternalEvent(eventTypeId) {
      return includes(
        [
          VmsConstants.eventTypes.EVENT_TYPE_ID_GOOGLE_CALENDAR_EVENT,
          VmsConstants.eventTypes.EVENT_TYPE_ID_BAMBOO_EVENT,
        ],
        eventTypeId
      )
    },

    handleTakeOffHold() {
      this.unholdJobEvent({
        loadEventsList: true,
        event_job_type: this.eventJobType,
        payload: {
          page: 1,
          notCancelable: true,
        },
        project_id: this.selectedEvent.project.id,
        order_job_id: this.selectedEvent.order_job_element.order_job_id,
      })
    },
  },
}
</script>
